import styled from "styled-components"

function getColorSequence(len, linksColor) {
  let styles = ""
  for (let i = 0; i < len; i += 1) {
    styles += `#link-${i} {
      :hover {
        border-left: 4px solid var(--color-${linksColor});
      }
    }`
  }
  return styles
}

const StyledSlidedown = styled.ul`
  min-width: 300px;
  list-style: none;
  text-align: start;
  margin: 0;
  position: absolute;
  top: 62px;
  left: 0px;
  box-shadow: var(--shadow-sm);
  background-color: var(--color-background);
  color: var(--color-text);
  padding: 0;

  li {
    cursor: pointer;
    padding: 7px;
    display: block;
    border-left: 4px solid var(--color-background);
  }
  li a {
    display: block;
  }
  ${({ elementsNo, linksColor }) => getColorSequence(elementsNo, linksColor)}
`

export default StyledSlidedown
