import { useEffect, useState } from "react"
import { OptionModel } from "components/helpers"
import { getPaymentUserStructureOptions } from "queries"

export interface UserStructure {
  id: number
  name: string
  options: OptionModel[]
}

export interface UserStructures {
  firstUserStructure?: UserStructure
  secondUserStructure?: UserStructure
  thirdUserStructure?: UserStructure
}

export interface UserStructureApiResponse {
  id: number
  name: string
  options: any[]
}

export interface UserStructuresApiResponse {
  firstUserStructure?: UserStructureApiResponse
  secondUserStructure?: UserStructureApiResponse
  thirdUserStructure?: UserStructureApiResponse
}

export const createEmptyUserStructures = (): UserStructures => ({
  firstUserStructure: {
    id: 0,
    name: "",
    options: [],
  },
  secondUserStructure: {
    id: 0,
    name: "",
    options: [],
  },
  thirdUserStructure: {
    id: 0,
    name: "",
    options: [],
  },
})

const usePaymentUserStructureFilter = () => {
  const [userStructures, setUserStructures] = useState<UserStructures>(
    createEmptyUserStructures()
  )

  const onCompletedUserStructureOptions = (data: UserStructuresApiResponse) => {
    const userPermissions: UserStructures = {
      firstUserStructure: data?.firstUserStructure
        ? {
            id: data.firstUserStructure?.id,
            name: data.firstUserStructure?.name,
            options: data.firstUserStructure?.options.map((x) => ({
              value: x.name,
              title: x.name,
            })),
          }
        : undefined,
      secondUserStructure: data.secondUserStructure
        ? {
            id: data.secondUserStructure?.id,
            name: data.secondUserStructure?.name,
            options: data.secondUserStructure?.options.map((x) => ({
              value: x.name,
              title: x.name,
            })),
          }
        : undefined,
      thirdUserStructure: data.thirdUserStructure
        ? {
            id: data.thirdUserStructure?.id,
            name: data.thirdUserStructure?.name,
            options: data.thirdUserStructure?.options.map((x) => ({
              value: x.name,
              title: x.name,
            })),
          }
        : undefined,
    }
    setUserStructures(userPermissions)
  }

  const getUserStructures = async () => {
    const response = await getPaymentUserStructureOptions()
    if (response?.status === 200) {
      onCompletedUserStructureOptions(response.data)
    }
  }

  useEffect(() => {
    getUserStructures()
  }, [])

  return userStructures
}

export default usePaymentUserStructureFilter
