import React from "react"
import Permissions from "components/helpers/permissions"
import MaintenanceRoutes from "./MaintenanceRoutes"
import PriceManagementRoutes from "./PriceManagementRoutes"
import FinanceRoutes from "./FinanceRoutes"
import ReportingRoutes from "./ReportingRoutes"
import CollaborationRoutes from "./CollaborationRoutes"

// Top level routing of the application
const TopLevelRoutes = [
  {
    location: "/Dashboard",
    name: "top-dashboard",
    routeOnly: true,
    Component: React.lazy(() => import("views/Dashboard")),
  },

  {
    location: "/maintenance",
    name: "top-maintenance",
    color: "ebateSecondary",
    hideOnTablet: true,
    dropdownRoutes: MaintenanceRoutes,
    requiredPermissions: [
      Permissions.MaintenanceAccountingPeriod,
      Permissions.MaintenanceClientConfiguration,
      Permissions.MaintenanceCustomerMaintenance,
      Permissions.MaintenanceCrossReference,
      Permissions.MaintenanceCurrency,
      Permissions.MaintenanceDocumentCategory,
      Permissions.MaintenanceDocumentTag,
      Permissions.MaintenanceProductMaintenance,
      Permissions.MaintenanceProjectMaintenance,
      Permissions.MaintenanceTradingGroup,
      Permissions.MaintenanceUsers,
      Permissions.MaintenanceRoles,
      Permissions.MaintenanceWholeSalerDiscount,
      Permissions.MaintenanceWorkflow,
    ],
  },
  {
    location: "/rebateManagement",
    name: "top-rebate-management",
    color: "ebateSecondary",
    dropdownRoutes: PriceManagementRoutes,
    requiredPermissions: [
      Permissions.PricingManagementPackage,
      Permissions.PricingManagementAgreement,
      Permissions.PricingManagementApprovals,
      Permissions.PricingManagementCalcEngineStatus,
      Permissions.PricingManagementRebate,
      Permissions.PricingManagementPackage,
    ],
  },
  {
    location: "/finance",
    name: "top-finance",
    color: "ebateSecondary",
    dropdownRoutes: FinanceRoutes,
    requiredPermissions: [
      Permissions.FinanceAccruals,
      Permissions.FinancePaymentAdjustments,
      Permissions.FinancePayments,
      Permissions.FinanceApproveFOCClaims,
      Permissions.FinanceClaimPaymentStatus,
      Permissions.FinanceImportData,
    ],
  },
  {
    location: "/reporting",
    name: "top-reporting",
    color: "ebateSecondary",
    dropdownRoutes: ReportingRoutes,
    requiredPermissions: [Permissions.ReportingReportServer],
  },
  {
    location: "/collaboration",
    name: "top-collaboration",
    color: "ebateSecondary",
    dropdownRoutes: CollaborationRoutes,
    requiredPermissions: [
      Permissions.CollaborationDocumentLibrary,
      Permissions.CollaborationContracts,
      Permissions.CollaborationProduct,
      Permissions.CollaborationNewsFeed,
      Permissions.CollaborationDocumentManagement,
      Permissions.CollaborationImportTransactions,
      Permissions.CollaboratationPortalUserManagment,
      Permissions.CollaboratationTradeAgreements,
    ],
  },
  {
    location: "/login",
    fallbackLocation: "/dashboard",
  },
]
export default TopLevelRoutes
