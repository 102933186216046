const QUERY_KEY_ACCOUNT_TYPES = ["accountTypes"]
const QUERY_KEY_ACCRUAL_TYPES = ["accrualTypes"]
const QUERY_KEY_AGGREGATION_LEVELS = ["aggregationLevels"]
const QUERY_KEY_AGREEMENTS_BY_CALCULATION_TYPE = ["agreementsByCalculationType"]
const QUERY_AGREEMENTS_BY_WORKFLOW_TYPE = ["agreementsByWorkflowType"]
const QUERY_KEY_AGREEMENT_STATUS_VALUES = ["agreementStatus"]
const QUERY_KEY_CALCULATION_TYPES_COMBO_VALUES = ["calculationTypesComboValues"]
const QUERY_KEY_CALCULATION_TYPES_EXPORTABLES = ["calculationTypesExportables"]
const QUERY_KEY_CLAIM_TYPES = ["claimTypes"]
const QUERY_KEY_COMPANIES_DROPDOWN = ["companiesDropdown"]
const QUERY_KEY_COMPANY_TYPES = ["companyTypes"]
const QUERY_KEY_COMPANY_TYPE_COMBO_VALUES_BY_USER = [
  "companyTypeComboValuesByUser",
]
const QUERY_KEY_CROSS_REFERENCE_VALUES = ["getCrossReferenceValues"]
const QUERY_KEY_DATA_TYPES = ["dataTypes"]
const QUERY_KEY_DOCUMENT_CATEGORIES = ["Document Categories"]
const QUERY_KEY_DOCUMENT_SIZE_LIMIT = ["documentSizeLimit"]
const QUERY_KEY_DOCUMENT_TYPES = ["documentTypes"]
const QUERY_KEY_DOCUMENT_TAGS = ["Document Tags"]
const QUERY_KEY_EXTENSION_FIELD_VALUES = ["extensionFieldValues"]
const QUERY_KEY_IMPORT_NAMES = ["importNames"]
const QUERY_KEY_PAY_TO_TYPES = ["payToTypes"]
const QUERY_KEY_PAYMENT_ADJUSTMENT_REASONS = ["paymentAdjustmentReasons"]
const QUERY_KEY_PERIODS = ["periods"]
const QUERY_KEY_PRICE_TYPES = ["priceTypes"]
const QUERY_KEY_PRODUCT_CODES_BY_CALCULATION_TYPE = [
  "productCodesByCalculationType",
]
const QUERY_KEY_RATES_BY_CALCULATION_TYPE = ["ratesByCalculationType"]
const QUERY_KEY_REBATE_CATEGORIES = ["rebateCategories"]
const QUERY_KEY_REBATE_PAY_TO_COMPANIES = ["rebatePayToCompanies"]
const QUERY_KEY_REBATES_BY_CALCULATION_TYPE = ["rebatesByCalculationType"]
const QUERY_KEY_REQUEST_STATUSES = ["requestStatuses"]
const QUERY_KEY_UNIT_OF_MEASUREMENTS = ["unitOfMeasurements"]
const QUERY_KEY_USER_STRUCTURES_VALUES = ["userStructuresValues"]
const QUERY_KEY_WORKFLOW_TYPES_FOR_AUDIT = ["workflowTypesForAudit"]

export default {
  QUERY_KEY_ACCOUNT_TYPES,
  QUERY_KEY_ACCRUAL_TYPES,
  QUERY_KEY_AGREEMENTS_BY_CALCULATION_TYPE,
  QUERY_KEY_AGGREGATION_LEVELS,
  QUERY_AGREEMENTS_BY_WORKFLOW_TYPE,
  QUERY_KEY_AGREEMENT_STATUS_VALUES,
  QUERY_KEY_CALCULATION_TYPES_COMBO_VALUES,
  QUERY_KEY_CALCULATION_TYPES_EXPORTABLES,
  QUERY_KEY_CLAIM_TYPES,
  QUERY_KEY_COMPANIES_DROPDOWN,
  QUERY_KEY_COMPANY_TYPES,
  QUERY_KEY_COMPANY_TYPE_COMBO_VALUES_BY_USER,
  QUERY_KEY_CROSS_REFERENCE_VALUES,
  QUERY_KEY_DATA_TYPES,
  QUERY_KEY_DOCUMENT_CATEGORIES,
  QUERY_KEY_DOCUMENT_SIZE_LIMIT,
  QUERY_KEY_DOCUMENT_TYPES,
  QUERY_KEY_DOCUMENT_TAGS,
  QUERY_KEY_EXTENSION_FIELD_VALUES,
  QUERY_KEY_IMPORT_NAMES,
  QUERY_KEY_PAY_TO_TYPES,
  QUERY_KEY_PAYMENT_ADJUSTMENT_REASONS,
  QUERY_KEY_PERIODS,
  QUERY_KEY_PRICE_TYPES,
  QUERY_KEY_PRODUCT_CODES_BY_CALCULATION_TYPE,
  QUERY_KEY_RATES_BY_CALCULATION_TYPE,
  QUERY_KEY_REBATE_CATEGORIES,
  QUERY_KEY_REBATE_PAY_TO_COMPANIES,
  QUERY_KEY_REBATES_BY_CALCULATION_TYPE,
  QUERY_KEY_REQUEST_STATUSES,
  QUERY_KEY_UNIT_OF_MEASUREMENTS,
  QUERY_KEY_USER_STRUCTURES_VALUES,
  QUERY_KEY_WORKFLOW_TYPES_FOR_AUDIT,
}
