import { dropdownUtils, OptionApi, OptionModel } from "components/helpers"
import { getPaymentStatus } from "queries"
import { useEffect, useState } from "react"

const usePaymentStatus = () => {
  const [statuses, setStatuses] = useState<OptionModel[]>([])

  const onCompleted = (data: OptionApi[]) => {
    const paymentStatusesFromApi =
      dropdownUtils.mapToDropDownOptionFromApi(data)
    setStatuses([...paymentStatusesFromApi])
  }

  const fetchPaymentStatus = async () => {
    const response = await getPaymentStatus()

    if (response?.status === 200) {
      onCompleted(response.data)
    }
  }

  useEffect(() => {
    fetchPaymentStatus()
  }, [])
  return statuses
}

export default usePaymentStatus
