import { useContext, useEffect } from "react"
import { AuthenticationContext, AzureProviderContext } from "stores"
import { IdleTimer } from "components/helpers"
import { refreshToken, signOutApi } from "queries"

const timeoutMinutes = 10

const AuthBlock = () => {
  const { isAuthorized, storeAuthToken, secondsLeft, setSecondsLeft, signOut } =
    useContext(AuthenticationContext)
  const azureContext = useContext(AzureProviderContext)

  const doRefresh = async () => {
    const response = await refreshToken()

    if (response.status === 200) {
      if (response.data.valid) {
        storeAuthToken(response.data.data.idToken, response.data.data.expiresIn)
      } else {
        if (azureContext && azureContext.authProvider)
          azureContext.authProvider.logout()
        signOut()
      }
    }
  }

  useEffect(() => {
    const timerId = setTimeout(() => {
      setSecondsLeft(secondsLeft - 60)
      if (isAuthorized) {
        if (secondsLeft < 61) {
          doRefresh()
        }
      }
    }, 60000)

    return () => clearTimeout(timerId)
  }, [doRefresh, isAuthorized, secondsLeft, setSecondsLeft, storeAuthToken])

  useEffect(() => {
    let timer
    if (isAuthorized) {
      timer = new IdleTimer({
        timeout: timeoutMinutes * 60,
        onTimeout: async () => {
          await signOutApi()
          if (azureContext && azureContext.authProvider)
            azureContext.authProvider.logout()
          signOut()
        },
      })
    }

    return () => timer && timer.cleanUp()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthorized])

  return null
}

export default AuthBlock
