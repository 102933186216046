import { ebateQueryKeys, useEbateQuery } from "queries/ebateReactQuery"
import {
  CacheTime,
  StaleTime,
} from "queries/ebateReactQuery/ebateReactQuery.models"
import { getCalculationTypeComboValues } from "../calculationType.query"

export const useCalculationTypesComboValues = () => {
  const onCompletedCalculationTypes = (data: any) => {
    const calculationTypesMapped = data.map((c) => ({
      value: c.id,
      title: c.description,
      isTier: c.isTier,
    }))
    return [...calculationTypesMapped]
  }
  const fetchAccountTypes = async () => {
    const response = await getCalculationTypeComboValues()
    if (response?.status === 200) {
      return onCompletedCalculationTypes(response.data)
    }
    throw new Error(response.statusText)
  }

  const accountTypes = useEbateQuery(
    [...ebateQueryKeys.QUERY_KEY_CALCULATION_TYPES_COMBO_VALUES],
    fetchAccountTypes,
    {
      cacheTime: CacheTime.long,
      staleTime: StaleTime.long,
    }
  )
  return accountTypes
}
