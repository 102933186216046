import { ValidationResultModel } from "components/models"
import { DocumentCategoryEditRequest } from "components/pages/maintanance/documentCategory/documentCategoryForm/documentCategoryForm.models"
import {
  CategoryGridResponse,
  DocumentCategoryRequest,
} from "components/pages/maintanance/documentCategory/documentCategoryList/documentCategoryList.models"
import EbateAxios from "queries/ebateAxios"

const documentCategoryUrl = "/api/DocumentCategory"

export const getCategoryGridData = async (data: DocumentCategoryRequest) => {
  const url = `${documentCategoryUrl}/GetCategoryGridData`
  const { AxiosPost } = EbateAxios()

  return AxiosPost<DocumentCategoryRequest, CategoryGridResponse>(url, data)
}

export const addDocumentCategory = async (text: string) => {
  const url = `${documentCategoryUrl}/Add`
  const { AxiosPost } = EbateAxios()

  return AxiosPost<any, ValidationResultModel>(url, text)
}

export const updateDocumentCategory = async (
  data: DocumentCategoryEditRequest
) => {
  const url = `${documentCategoryUrl}/Update`
  const { AxiosPut } = EbateAxios()

  return AxiosPut<DocumentCategoryEditRequest, ValidationResultModel>(url, data)
}

export const deleteDocumentCategory = async (id: number) => {
  const url = `${documentCategoryUrl}/Delete/${id}`
  const { AxiosPut } = EbateAxios()

  return AxiosPut<any, ValidationResultModel>(url, {})
}
