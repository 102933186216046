import styled from "styled-components"

const StyledRouteManager = styled.div`
  background-color: ${({ bgcolor, transparent }) =>
    bgcolor || (transparent ? "transparent" : "var(--color-background)")};
  border-top: ${({ borderColor }) =>
    borderColor ? `solid 1px ${borderColor}` : "none"};
`

export default StyledRouteManager
