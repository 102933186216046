import styled from "styled-components"

const StyledBaseWidget = styled.div`
  width: 100%;
  height: inherit;
  overflow-y: auto;
  overflow-x: hidden;

  background: var(--color-background);
  color: rgba(0, 0, 0, 0.87);
  box-shadow: var(--shadow-sm);
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  margin: var(--spacing-base);

  .wdg-header {
    display: flex;
    width: 100%;
    background-color: var(--color-ebatePrimary);
    max-height: 36px;
    padding: var(--spacing-base);
  }

  .wdg-heading {
    color: var(--color-white);
    font-size: var(--font-size-lg);
    font-family: var(--font-bold);
  }
`
export default StyledBaseWidget
