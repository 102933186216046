import { FilterModel } from "components/library"
import { GridDataResponse } from "components/models"

export interface ProductGridData {
  id: number
  productCode: string
  description: string
  unitOfMeasurement: string
}

export interface ProductGridDataResponse extends GridDataResponse {
  productGridItems: ProductGridData[]
}

export const fields = {
  id: "id",
  productCode: "productCode",
  description: "description",
  unitOfMeasurement: "unitOfMeasurement",
}

export const createEmptyProductGridData = (): ProductGridData[] => [
  {
    id: 0,
    productCode: "",
    description: "",
    unitOfMeasurement: "",
  },
]

export interface ProductGridRequest {
  statusId?: number
  uOM?: number
  currentPage: number
  recordsPerPage: number
  orderBy: string
  isAscending: boolean
  filter?: string
  search: string
}

export const createProductListRequest = () => {
  const productGridRequest: ProductGridRequest = {
    currentPage: 1,
    recordsPerPage: 50,
    orderBy: "Id",
    isAscending: true,
    filter: "",
    search: "",
  }
  return productGridRequest
}

export const createProductListRequestWithFilter = (filter: string) => {
  const productGridRequest: ProductGridRequest = {
    currentPage: 1,
    recordsPerPage: 50,
    orderBy: "Id",
    isAscending: true,
    search: filter,
  }
  return productGridRequest
}

export interface ProductGridFilterRequest {
  statusId: FilterModel<number>
  unitOfMeasurements: FilterModel<number>
}
export interface ProductGridFilter {
  statusId?: number
  uOM?: number
}
