import {
  EditorSelectionChangeParams,
  EditorTextChangeParams,
} from "primereact/editor"
import React from "react"
import { StyledTextEditor } from "./styled"

export enum HeaderTemplate {
  Basic,
  Extended,
}
interface Props {
  id?: string
  value?: string
  style?: object
  className?: string
  placeholder?: string
  readOnly?: boolean
  modules?: any
  formats?: string[]
  theme?: string
  showHeader?: boolean
  header?: HeaderTemplate
  // headerTemplate?: React.ReactNode
  onTextChange?(e: EditorTextChangeParams): void
  onSelectionChange?(e: EditorSelectionChangeParams): void
  onLoad?(quill: any): void
  children?: React.ReactNode
}
export const TextEditor: React.FC<Props> = ({ ...props }) => {
  const getHeaderTemplate = (
    header: HeaderTemplate | undefined
  ): React.ReactNode => {
    switch (header) {
      case HeaderTemplate.Basic:
        return (
          <div className="editing-button-header">
            <span className="ql-formats">
              <button className="ql-bold" type="button" aria-label="Bold" />
              <button className="ql-italic" type="button" aria-label="Italic" />
              <button
                className="ql-underline"
                type="button"
                aria-label="Underline"
              />
            </span>
          </div>
        )
      case HeaderTemplate.Extended:
        return (
          <div className="editing-button-header">
            <span className="ql-formats">
              <span className="ql-header ql-picker" style={{ display: "none" }}>
                <span
                  className="ql-picker-options"
                  aria-hidden="true"
                  tabIndex={-1}
                  id="ql-picker-options-0"
                >
                  <span
                    tabIndex={0}
                    role="button"
                    className="ql-picker-item"
                    data-value="1"
                    data-label="Heading"
                  />
                  <span
                    tabIndex={0}
                    role="button"
                    className="ql-picker-item"
                    data-value="2"
                    data-label="Subheading"
                  />
                  <span
                    tabIndex={0}
                    role="button"
                    className="ql-picker-item ql-selected"
                    data-value="0"
                    data-label="Normal"
                  />
                </span>
                <select className="ql-header" style={{ display: "none" }}>
                  <option value="1">Heading</option>
                  <option value="2">Subheading</option>
                  <option selected value="0">
                    Normal
                  </option>
                </select>
              </span>
            </span>
            <span className="ql-formats">
              <button className="ql-bold" type="button" aria-label="Bold" />
              <button className="ql-italic" type="button" aria-label="Italic" />
              <button
                className="ql-underline"
                type="button"
                aria-label="Underline"
              />
            </span>
            <span className="ql-formats">
              <button
                className="ql-list"
                type="button"
                value="ordered"
                aria-label="Ordered List"
              />
              <button
                className="ql-list"
                type="button"
                value="bullet"
                aria-label="Unordered List"
              />
              <span className="ql-align ql-picker ql-icon-picker" />
              <select className="ql-align" style={{ display: "none" }}>
                <option />
                <option value="center" />
                <option value="right" />
                <option value="justify" />
              </select>
            </span>
          </div>
        )
      default:
        return <></>
    }
  }
  return (
    <StyledTextEditor
      headerTemplate={getHeaderTemplate(props.header)}
      {...props}
      role="note"
    />
  )
}
