import { useEffect, useState, useContext } from "react"
import { AuthenticationContext } from "stores"
import { silentAuth } from "queries"

export const useAzureAuthentication = (token) => {
  const [hasPerformedSilentLogin, setHasPerformedSilentLogin] = useState(false)
  const { storeAuthToken } = useContext(AuthenticationContext)

  const performSilentLogin = async () => {
    await silentAuth(token)
      .then((response) => {
        if (response && response.status === 200) {
          setHasPerformedSilentLogin(true)
          storeAuthToken(
            response.data.data.idToken,
            response.data.data.expiresIn
          )
        } else {
          setHasPerformedSilentLogin(false)
        }
      })
      .catch(() => {
        setHasPerformedSilentLogin(false)
        storeAuthToken({})
      })
  }

  useEffect(() => {
    const performOktaAuth = async () => {
      // const token = await authProvider.getAccessToken()
      if (!hasPerformedSilentLogin) {
        performSilentLogin()
      }
    }
    performOktaAuth()
  }, [])

  return { hasPerformedSilentLogin }
}
