import { AxiosResponse } from "axios"
import { ValidationResultModel } from "components/models"
import {
  ClaimResponseApiModel,
  RoleDetailsApiModel,
  RoleDto,
  UpdateRoleAndClaimsRequest,
} from "components/pages/maintanance/roles/rolesDetails/roleDetailsModels"
import {
  AddRoleRequest,
  CopyRoleRequest,
} from "components/pages/maintanance/roles"
import { RolesGridData } from "components/pages/maintanance/roles/rolesList/rolesList.models"
import EbateAxios from "queries/ebateAxios"
import { GenericApiResponseModel } from "components/models/common.model"

const userRolesUrl = "/api/UserRoles"

export const getRoleClaims = async (isPortal: boolean) => {
  const { AxiosGet } = EbateAxios()

  return AxiosGet<ClaimResponseApiModel[]>(
    `${userRolesUrl}/GetRoleClaims/${isPortal}`
  )
}

export const getRoleDetails = async (id: string) => {
  const { AxiosGet } = EbateAxios()

  return AxiosGet<RoleDetailsApiModel>(`${userRolesUrl}/GetRoleDetails/${id}`)
}

export const getRolesGridData = async (isPortal: boolean) => {
  const url = `${userRolesUrl}/GetRolesGridData/${isPortal}`
  const { AxiosGet } = EbateAxios()

  return AxiosGet<RolesGridData>(url)
}

export const updateRole = async (
  request: UpdateRoleAndClaimsRequest
): Promise<AxiosResponse<ValidationResultModel>> => {
  const url = `${userRolesUrl}/UpdateRoleAndClaims`
  const { AxiosPut } = EbateAxios()

  return AxiosPut<UpdateRoleAndClaimsRequest, ValidationResultModel>(
    url,
    request
  )
}

export const addRole = async (data: AddRoleRequest) => {
  const url = `${userRolesUrl}/AddRole`
  const { AxiosPost } = EbateAxios()

  return AxiosPost<AddRoleRequest, GenericApiResponseModel<RoleModel>>(
    url,
    data
  )
}
export const copyRole = async (data: CopyRoleRequest) => {
  const url = `${userRolesUrl}/CopyRole`
  const { AxiosPost } = EbateAxios()

  return AxiosPost<CopyRoleRequest, GenericApiResponseModel<RoleModel>>(
    url,
    data
  )
}

export const getNormalRoles = async (isPortal: boolean) => {
  const { AxiosGet } = EbateAxios()

  return AxiosGet<RoleDto[]>(`${userRolesUrl}/GetNormalRoles/${isPortal}`)
}

export interface RoleModel {
  id: string
}
