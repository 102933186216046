class TenantSpecOptions {
  readonly subscription?: boolean

  constructor(subscription?: boolean) {
    if (subscription !== undefined) {
      this.subscription = subscription
    }
  }
}

export default TenantSpecOptions
