import { ValidationResultModel } from "components/models"
import { NewsFeedApiModel } from "components/pages/collaboration/newsFeed/newsFeedForm/newsFeedForm.models"
import {
  NewsFeedByCompanyRequest,
  NewsFeedGridResponse,
  NewsFeedRequest,
} from "components/pages/collaboration/newsFeed/newsFeedList/NewsFeedList.models"
import EbateAxios from "queries/ebateAxios"

const newsFeedUrl = "/api/NewsFeed"

export const getNewsFeedGridData = async (data: NewsFeedRequest) => {
  const url = `${newsFeedUrl}/GetNewsFeedGridData`
  const { AxiosPost } = EbateAxios()

  return AxiosPost<NewsFeedRequest, NewsFeedGridResponse>(url, data)
}

export const addNewsFeed = async (data: NewsFeedApiModel) => {
  const url = `${newsFeedUrl}/AddNewsFeed`
  const { AxiosPost } = EbateAxios()

  return AxiosPost<NewsFeedApiModel, ValidationResultModel>(url, data)
}

export const deleteNewsFeed = async (id: number) => {
  const url = `${newsFeedUrl}/DeleteNewsFeed/${id}`
  const { AxiosPut } = EbateAxios()

  return AxiosPut<any, ValidationResultModel>(url, {})
}

export const updateNewsFeed = async (id: number, data) => {
  const url = `${newsFeedUrl}/UpdateNewsFeed/${id}`
  const { AxiosPut } = EbateAxios()

  return AxiosPut<any, ValidationResultModel>(url, data)
}

export const getNewsFeedsByCompanyId = async (
  data: NewsFeedByCompanyRequest
) => {
  const url = `${newsFeedUrl}/GetNewsFeedsByCompanyId`
  const { AxiosPost } = EbateAxios()

  return AxiosPost<NewsFeedByCompanyRequest, NewsFeedGridResponse>(url, data)
}
