import React from "react"
import { Chart } from "primereact/chart"

interface Props {
  type: string
  data: FilledGraphData
}

interface FilledGraphDataset {
  label: string
  data: any
  borderColor: any
  fill: boolean
  tension?: number
  backgroundColor: any
}

export interface FilledGraphData {
  labels: string[]
  datasets: FilledGraphDataset[]
}

const basicOptions = {
  maintainAspectRatio: false,
  aspectRatio: 0.2,
}

const FilledGraphWidget: React.FC<Props> = ({ type, data }) => {
  const FieldData = {
    ...data,
    datasets: data?.datasets?.map((f) => ({ ...f, fill: true })),
  }
  return (
    <Chart type={type} data={FieldData} options={basicOptions} height="300px" />
  )
}

export default FilledGraphWidget
