import React from "react"
import { Link } from "react-router-dom"
import { StyledSlidedown } from "./styled"

const Slidedown = ({ menuItems, linksColor, linkItemSelected }) => (
  <StyledSlidedown elementsNo={menuItems.length} linksColor={linksColor}>
    {menuItems.map((item, index) => {
      if (item.routeOnly === true) return null
      const isExternalUrl = item.redirectToExternalUrl
      if (isExternalUrl) {
        return (
          <li key={item.location} id={`link-${index}`}>
            <Link
              to={{ pathname: item.location }}
              target="_blank"
              key={item.location}
              onClick={() => linkItemSelected()}
            >
              {item.name}
            </Link>
          </li>
        )
      }
      return (
        <li key={item.location} id={`link-${index}`}>
          <Link
            to={item.location}
            key={item.location}
            onClick={() => linkItemSelected()}
          >
            {item.name}
          </Link>
        </li>
      )
    })}
  </StyledSlidedown>
)

export default Slidedown
