import { useEffect, useState } from "react"
import { OptionModel } from "components/helpers"
import { getUserPermissionOptions } from "queries"

export interface UserPermission {
  id: number
  name: string
  options: OptionModel[]
}

export interface UserPermissions {
  firstPermission?: UserPermission
  secondPermission?: UserPermission
  thirdPermission?: UserPermission
}

export interface UserPermissionApiResponse {
  id: number
  name: string
  options: any[]
}

export interface UserPermissionsApiResponse {
  firstPermission?: UserPermissionApiResponse
  secondPermission?: UserPermissionApiResponse
  thirdPermission?: UserPermissionApiResponse
}

export const createEmptyUserPermissions = (): UserPermissions => ({
  firstPermission: {
    id: 0,
    name: "",
    options: [],
  },
  secondPermission: {
    id: 0,
    name: "",
    options: [],
  },
  thirdPermission: {
    id: 0,
    name: "",
    options: [],
  },
})

const useFullUserStructures = () => {
  const [userStructures, setUserStructures] = useState<UserPermissions>(
    createEmptyUserPermissions()
  )

  const onCompletedUserPermissionOptions = (
    data: UserPermissionsApiResponse
  ) => {
    const userPermissions: UserPermissions = {
      firstPermission: data?.firstPermission
        ? {
            id: data.firstPermission?.id,
            name: data.firstPermission?.name,
            options: data.firstPermission?.options.map((x) => ({
              value: x.text,
              title: x.name,
            })),
          }
        : undefined,
      secondPermission: data.secondPermission
        ? {
            id: data.secondPermission?.id,
            name: data.secondPermission?.name,
            options: data.secondPermission?.options.map((x) => ({
              value: x.text,
              title: x.name,
            })),
          }
        : undefined,
      thirdPermission: data.thirdPermission
        ? {
            id: data.thirdPermission?.id,
            name: data.thirdPermission?.name,
            options: data.thirdPermission?.options.map((x) => ({
              value: x.text,
              title: x.name,
            })),
          }
        : undefined,
    }
    setUserStructures(userPermissions)
  }

  const getUserPermissions = async () => {
    const response = await getUserPermissionOptions()
    if (response?.status === 200) {
      onCompletedUserPermissionOptions(response.data)
    }
  }

  useEffect(() => {
    getUserPermissions()
  }, [])

  return userStructures
}

export default useFullUserStructures
