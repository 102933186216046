import {
  Column,
  ColumnBodyOptions,
  ColumnEditorType,
  ColumnEventParams,
} from "primereact/column"
import React, { useState } from "react"
import {
  DataTable,
  DataTableEditingRows,
  DataTableExpandedRows,
  DataTableRowEditParams,
  DataTableRowExpansionTemplate,
} from "primereact/datatable"
import { IconButton, IconContainer } from "components/library"
import { StyledDataTableCrud } from "./styled"

export interface ColumnModel {
  field: string
  header: string
  sortable: boolean
  body?: (rowData: any, info?: ColumnBodyOptions) => JSX.Element | any
  style?: object
  hidden?: boolean
  expander?: boolean
  editor?: ColumnEditorType
  onCellEditComplete?: (e: ColumnEventParams) => void
}

export interface IconModel {
  icon: string
  action: () => any
  isDisabled?: boolean
  isReadOnly?: boolean
}

export enum SelectionMode {
  Single = "single",
  Multiple = "multiple",
}

export enum SelectionControl {
  Checkbox = "checkbox",
  Radiobutton = "radiobutton",
}

export interface Props {
  columns: ColumnModel[]
  icons?: IconModel[]
  selection?: any
  onSelectionChange?: (e: any) => any
  value: object[]
  dataKey: string
  isSelectable?: boolean
  rowHover?: boolean
  scrollable?: boolean
  scrollHeight?: string
  topPosition?: string
  marginTop?: boolean
  onSort?: any
  multiSortMeta?: any
  loading?: boolean
  sortField?: string
  sortOrder?: any
  selectionMode?: SelectionMode
  selectionControl?: SelectionControl
  rowExpansionTemplate?: (
    data: any,
    options: DataTableRowExpansionTemplate
  ) => React.ReactNode
  onRowSelect?: (e: any) => void
  onRowUnselect?: (e: any) => void
  editable?: boolean
  editingRows?: any[] | DataTableEditingRows
  onRowEditChange?: (e: DataTableRowEditParams) => void
}

export const DataTableCrud: React.FC<Props> = (props) => {
  const [expandedRows, setExpandedRows] = useState<
    any[] | DataTableExpandedRows
  >()

  const isDisableIcon = (icon: IconModel): boolean => {
    if (icon.isDisabled !== null && icon.isDisabled !== undefined) {
      return !props.selection || !props.selection.length
    }
    return false
  }
  const renderIcons = (icons: IconModel[]) => (
    <IconContainer>
      {icons.map(
        (icon: IconModel) =>
          !icon.isReadOnly && (
            <IconButton
              key={icon.icon}
              icon={icon.icon}
              onClick={icon.action}
              isDisabled={isDisableIcon(icon)}
            />
          )
      )}
    </IconContainer>
  )

  return (
    <StyledDataTableCrud
      topPosition={props.topPosition}
      marginTop={props.marginTop}
    >
      <div className="iconHeader">
        {props.icons && renderIcons(props.icons)}
      </div>
      <DataTable
        loading={props.loading}
        selection={props.selection}
        onSelectionChange={props.onSelectionChange}
        value={props.value}
        dataKey={props.dataKey}
        scrollable={props.scrollable}
        scrollHeight={props.scrollHeight ?? "flex"}
        rowHover={props.rowHover}
        onSort={props.onSort}
        removableSort
        multiSortMeta={props.multiSortMeta}
        sortField={props.sortField}
        sortOrder={props.sortOrder}
        responsiveLayout="stack"
        breakpoint="0px"
        selectionMode={props.selectionControl ?? undefined}
        expandedRows={props.rowExpansionTemplate ? expandedRows : undefined}
        rowExpansionTemplate={props.rowExpansionTemplate}
        onRowToggle={
          props.rowExpansionTemplate
            ? (e) => {
                setExpandedRows(e.data)
              }
            : undefined
        }
        onRowSelect={props.onRowSelect}
        onRowUnselect={props.onRowUnselect}
        editMode={props.editable ? "row" : undefined}
        editingRows={props.editingRows}
        onRowEditChange={props.onRowEditChange}
      >
        {props.isSelectable && (
          <Column
            selectionMode={props.selectionMode ?? SelectionMode.Multiple}
            headerStyle={{ width: "3em" }}
          />
        )}
        {props.rowExpansionTemplate && (
          <Column expander headerStyle={{ width: "3em" }} />
        )}
        {props.columns.map((c) => (
          <Column
            key={c.field}
            field={c.field}
            header={c.header}
            sortable={c.sortable}
            body={c.body}
            style={c.style}
            hidden={c.hidden}
            expander={c.expander}
            editor={c.editor}
            onCellEditComplete={c.onCellEditComplete}
          />
        ))}
      </DataTable>
    </StyledDataTableCrud>
  )
}
