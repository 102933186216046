import { TenantSpecOptions } from "models"
import {
  createEmptyTenantConfiguration,
  TenantConfiguration,
} from "models/beconfiguration/beconfiguration.models"
import { getTenantConfiguration } from "queries"
import { useEffect, useState } from "react"

const useConfiguration = (): [
  TenantSpecOptions,
  boolean,
  boolean,
  TenantConfiguration
] => {
  const [configLoaded, setConfigLoaded] = useState(false)
  const [is10DecimalsActive, setIs10DecimalsActive] = useState(false)

  const [tenantSpecOptions, setTenantSpecOptions] = useState(
    new TenantSpecOptions()
  )
  const [tenantConfiguration, setTenantConfiguration] = useState(
    createEmptyTenantConfiguration()
  )

  const getTenantConfigurationOptions = async () => {
    try {
      const response = await getTenantConfiguration()

      if (response) {
        if (response.status === 200) {
          setTenantConfiguration(response.data)

          const tOptions = new TenantSpecOptions(response.data.subscription)

          setTenantSpecOptions(tOptions)
          setConfigLoaded(true)
        }
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e)
    }
  }

  useEffect(() => {
    getTenantConfigurationOptions()
    calculateDecimalsActive()
  }, [])

  const calculateDecimalsActive = () => {
    if (
      process.env.REACT_APP_DOMAINS_10DECIMALS?.split(";").some((domain) =>
        window.location.href.includes(domain)
      )
    ) {
      setIs10DecimalsActive(true)
    } else {
      setIs10DecimalsActive(false)
    }
  }

  return [
    tenantSpecOptions,
    configLoaded,
    is10DecimalsActive,
    tenantConfiguration,
  ]
}

export default useConfiguration
