import EbateAxios from "queries/ebateAxios"
import { UserStructuresApiResponse } from "components/hooks/fetch"
import { PaymentFilterRequest } from "components/pages/finance/ClaimsPayments/claimPaymentFilter/claimPaymentFilter.models"
import { ClaimPaymentGridData } from "components/pages/finance/ClaimsPayments/claimPaymentList/claimsPayments.models"
import { PaymentProcessRequest } from "components/pages/finance/ClaimsPayments/claimPaymentList/confirmationProcessPayment/confirmationProcessPayment.model"
import { ValidationResultModel } from "components/models"
import {
  PaymentAdditionalCompanyFilterRequest,
  CustomerInfoResponse,
} from "components/pages/finance/ClaimsPayments/customerInfo/customerInfo.models"
import { RebatePaymentSummaryModel } from "components/pages/pricemanagement/rebates/rebatePayments/rebatePaymentsSummary/rebatePaymentsSummary.models"
import {
  RebatePaymentsFilterRequest,
  RebatePaymentsGridResponse,
} from "components/pages/pricemanagement/rebates/rebatePayments/rebatePaymentsDetail/rebatePaymentsDetail.models"

const paymentUrl = "/api/Payment"

export const getPaymentUserStructureOptions = async () => {
  const url = `${paymentUrl}/GetPaymentUserStructureFilters`
  const { AxiosGet } = EbateAxios()

  return AxiosGet<UserStructuresApiResponse>(url)
}

export const getClaimsAndPayments = async (
  data: PaymentFilterRequest,
  abortSignal?: AbortSignal
) => {
  const url = `${paymentUrl}/GetClaimsAndPaymentFilters`
  const { AxiosPost } = EbateAxios()
  return AxiosPost<PaymentFilterRequest, ClaimPaymentGridData[]>(
    url,
    data,
    abortSignal
  )
}

export const processPayment = async (data: PaymentProcessRequest) => {
  const url = `${paymentUrl}/ProcessPayment`
  const { AxiosPost } = EbateAxios()
  return AxiosPost<PaymentProcessRequest, ValidationResultModel>(url, data)
}

export const getAdditionalCompanyClaimsAndPaymentsByFilters = async (
  data: PaymentAdditionalCompanyFilterRequest
) => {
  const url = `${paymentUrl}/GetAdditionalCompanyClaimsAndPaymentsByFilters`
  const { AxiosPost } = EbateAxios()
  return AxiosPost<PaymentAdditionalCompanyFilterRequest, CustomerInfoResponse>(
    url,
    data
  )
}

export const getPaymentsSummaryByRebate = async (rebateId: number) => {
  const url = `${paymentUrl}/GetPaymentsSummaryByRebate/${rebateId}`
  const { AxiosGet } = EbateAxios()

  return AxiosGet<RebatePaymentSummaryModel>(url)
}

export const getRebatePayments = async (data: RebatePaymentsFilterRequest) => {
  const url = `${paymentUrl}/GetRebatePayments`
  const { AxiosPost } = EbateAxios()
  return AxiosPost<RebatePaymentsFilterRequest, RebatePaymentsGridResponse>(
    url,
    data
  )
}
