import { dropdownUtils, OptionApi, OptionModel } from "components/helpers"
import { useEffect, useState } from "react"
import { getRebateConditionTypes } from "queries"

export const useRebateConditionTypes = () => {
  const [conditionTypes, setConditionTypes] = useState<OptionModel[]>([])

  const onCompletedConditionTypes = (res: OptionApi[]) => {
    const conditionTypesFromApi = dropdownUtils.mapToDropDownOptionFromApi(res)
    setConditionTypes([...conditionTypesFromApi])
  }

  const fetchRebateConditionTypes = async () => {
    const response = await getRebateConditionTypes()

    if (response?.status === 200) {
      onCompletedConditionTypes(response.data)
    }
  }

  useEffect(() => {
    fetchRebateConditionTypes()
  }, [])
  return conditionTypes
}
