import EbateAxios from "queries/ebateAxios"
import {
  RolesUserData,
  CreateWorkflowLevelRequest,
  WorklowLevelTypeApi,
  UpdateWorkflowLevelRequest,
} from "components/pages/maintanance/workflow/workflowLevel/workflowLevelForm/WorkflowLevelForm.model"
import { ValidationResultModel } from "components/models"
import {
  WorkflowGridFilterRequest,
  WorkflowGridItem,
} from "components/pages/maintanance/workflow/workflowList/workflowList.models"
import {
  WorkflowItemApi,
  WorkflowUpdateRequestType,
} from "components/pages/maintanance/workflow/workflowDetails/workflowDetails.models"
import { CreateWorkflowRequest } from "components/pages/maintanance/workflow/workflowForm/WorkflowForm.model"

import { AxiosResponse } from "axios"

const userRolesDataUrl = "/api/UserRoles"
const workflowUrl = "/api/workflow"

export const getRolesAndUsers = async (isPortal) => {
  const { AxiosGet } = EbateAxios()
  return AxiosGet<RolesUserData[]>(
    `${userRolesDataUrl}/GetRolesAndUsers/${isPortal}`
  )
}

export const deleteWorkflow = async (
  id: number,
  checkWarning: boolean
): Promise<AxiosResponse<ValidationResultModel>> => {
  const url = `${workflowUrl}/DeleteWorkflow/${id}/${checkWarning}`
  const { AxiosDelete } = EbateAxios()

  return AxiosDelete<ValidationResultModel>(url)
}

export const deleteWorkflowLevel = async (
  id: number
): Promise<AxiosResponse<ValidationResultModel>> => {
  const url = `${workflowUrl}/DeleteWorkflowLevel/${id}`
  const { AxiosDelete } = EbateAxios()

  return AxiosDelete<ValidationResultModel>(url)
}
export const getWorkflowGridData = async (data: WorkflowGridFilterRequest) => {
  const url = `${workflowUrl}/GetWorkflowsGridData`
  const { AxiosPost } = EbateAxios()

  return AxiosPost<WorkflowGridFilterRequest, WorkflowGridItem>(url, data)
}

export const getWorkflowTypes = async () => {
  const { AxiosGet } = EbateAxios()

  return AxiosGet<any[]>(`${workflowUrl}/GetWorkflowTypes`)
}
export const getWorkflowById = async (workFlowId: number) => {
  const url = `${workflowUrl}/GetWorkflowById/${workFlowId}`
  const { AxiosGet } = EbateAxios()

  return AxiosGet<WorkflowItemApi>(url)
}
export const createWorkflow = async (data: CreateWorkflowRequest) => {
  const url = `${workflowUrl}/CreateWorkflow`
  const { AxiosPost } = EbateAxios()

  return AxiosPost<CreateWorkflowRequest, any>(url, data)
}

export const updateWorkflow = async (
  request: WorkflowUpdateRequestType
): Promise<AxiosResponse<any>> => {
  const url = `${workflowUrl}/UpdateWorkflow`
  const { AxiosPost } = EbateAxios()

  return AxiosPost<WorkflowUpdateRequestType, any>(url, request)
}

export const createWorkflowLevel = async (data: CreateWorkflowLevelRequest) => {
  const url = `${workflowUrl}/CreateWorkflowLevel`
  const { AxiosPost } = EbateAxios()

  return AxiosPost<CreateWorkflowLevelRequest, any>(url, data)
}
export const getWorkflowLevelById = async (workFlowLevelId: number) => {
  const url = `${workflowUrl}/GetWorkflowLevelById/${workFlowLevelId}`
  const { AxiosGet } = EbateAxios()

  return AxiosGet<WorklowLevelTypeApi>(url)
}
export const updateWorkflowLevel = async (
  request: UpdateWorkflowLevelRequest
): Promise<AxiosResponse<any>> => {
  const url = `${workflowUrl}/UpdateWorkflowLevel`
  const { AxiosPost } = EbateAxios()

  return AxiosPost<UpdateWorkflowLevelRequest, any>(url, request)
}
