import { OptionApi } from "components/helpers"
import { ValidationResultModel } from "components/models"
import {
  ClaimsGridFilterRequest,
  ClaimsGridResponse,
  FocFileSummaryResponse,
  RecalculateAllClaimsRequest,
  RecalculateClaimsRequest,
  ValidateClaimsRequest,
} from "components/pages/finance/approveFocClaims/approveFocClaims.models"
import EbateAxios from "queries/ebateAxios"

const focClaimsUrl = "/api/FOCClaims"

export const getClaimGridViewTypeList = async () => {
  const url = `${focClaimsUrl}/GetClaimGridViewTypeList`
  const { AxiosGet } = EbateAxios()

  return AxiosGet<OptionApi[]>(url)
}

export const getClaimsGridData = async (data: ClaimsGridFilterRequest) => {
  const { AxiosPost } = EbateAxios()

  const url = `${focClaimsUrl}/GetClaimsGridData`
  return AxiosPost<ClaimsGridFilterRequest, ClaimsGridResponse>(url, data)
}

export const getFocFileSummary = async (
  exchangeMappingId: number,
  accountingPeriodId: number
) => {
  const url = `${focClaimsUrl}/GetFocFileSummary/${exchangeMappingId}/${accountingPeriodId}`
  const { AxiosGet } = EbateAxios()

  return AxiosGet<FocFileSummaryResponse>(url)
}

export const recalculateAllClaims = async (
  data: RecalculateAllClaimsRequest
) => {
  const { AxiosPost } = EbateAxios()

  const url = `${focClaimsUrl}/recalculateAllClaims`
  return AxiosPost<RecalculateAllClaimsRequest, ValidationResultModel>(
    url,
    data
  )
}

export const recalculateClaims = async (data: RecalculateClaimsRequest) => {
  const { AxiosPost } = EbateAxios()

  const url = `${focClaimsUrl}/RecalculateClaims`
  return AxiosPost<RecalculateClaimsRequest, ValidationResultModel>(url, data)
}

export const approveFocClaimsReact = async (data: ValidateClaimsRequest) => {
  const { AxiosPost } = EbateAxios()

  const url = `${focClaimsUrl}/ApproveFocClaimsReact`
  return AxiosPost<ValidateClaimsRequest, ValidationResultModel>(url, data)
}

export const rejectFocClaimsReact = async (data: ValidateClaimsRequest) => {
  const { AxiosPost } = EbateAxios()

  const url = `${focClaimsUrl}/RejectFocClaimsReact`
  return AxiosPost<ValidateClaimsRequest, ValidationResultModel>(url, data)
}
