import { CurrencyModel } from "components/pages/maintanance/currency/currencyList/currencyList.models"

export interface TenantConfiguration {
  defaultCurrency: CurrencyModel
  userStructure1LoginEnabled: boolean
  rebateApprovalWorkflowEnabled: boolean
  reportServerUrl: string
  agreementApprovalEnabled: boolean
  subscription: boolean
}

export const createEmptyTenantConfiguration = (): TenantConfiguration => ({
  defaultCurrency: {} as CurrencyModel,
  rebateApprovalWorkflowEnabled: false,
  userStructure1LoginEnabled: false,
  reportServerUrl: "",
  agreementApprovalEnabled: false,
  subscription: false,
})
