import { dropdownUtils, OptionApi, OptionModel } from "components/helpers"
import { useEffect, useState } from "react"
import { getPackageStatusCombo } from "queries"

const usePackageStatus = () => {
  const [statuses, setStatuses] = useState<OptionModel[]>([])

  const onCompleted = (res: OptionApi[]) => {
    const packageStatusesFromApi = dropdownUtils.mapToDropDownOptionFromApi(res)
    setStatuses([...packageStatusesFromApi])
  }

  const fetchPackageStatus = async () => {
    const response = await getPackageStatusCombo()

    if (response?.status === 200) {
      onCompleted(response.data)
    }
  }

  useEffect(() => {
    fetchPackageStatus()
  }, [])
  return statuses
}

export default usePackageStatus
