import React, { useState } from "react"
import SlideDown from "react-slidedown"

import { BiChevronDown, BiChevronUp } from "react-icons/bi"
import { CardIconModel } from "components/models"
import { MenuCard } from "./MenuCard"
import { StyledSimpleCard } from "./styled"
import { IconContainer } from "../containers"

interface Props {
  height?: string
  slice?: string
  header?: string | JSX.Element
  subHeader?: string | JSX.Element
  footer?: string | JSX.Element
  icons?: CardIconModel
  expandedContent?: any
  body?: string | JSX.Element
  full?: boolean
  onExpand?: () => void
  name?: string
  numberOfCards?: number
  heightBody?: string
  hideBodyOnExpandedContent?: boolean
}
const SimpleCard: React.FC<Props> = ({
  height,
  slice,
  header,
  subHeader,
  footer,
  icons,
  expandedContent,
  body,
  full,
  onExpand,
  name,
  numberOfCards,
  heightBody,
  hideBodyOnExpandedContent,
}) => {
  const [isShown, setIsShown] = useState(false)
  const [isExpanded, setIsExpanded] = useState(false)
  const [isHeightLocked, setIsHeightLocked] = useState(true)

  const lockHeight = () => {
    setTimeout(() => {
      setIsHeightLocked(true)
    }, 1000)
  }
  return (
    <StyledSimpleCard
      height={isHeightLocked ? height : "auto"}
      slice={slice}
      full={full}
      numberOfCards={numberOfCards}
      name={name}
      onMouseLeave={() => setIsShown(false)}
      heightBody={heightBody}
    >
      <div className="card">
        {slice && <div className="card-slice" />}
        <div className="card-content">
          {header && (
            <div className="card-header-container">
              <div className="card-header">{header}</div>
              <div className="card-icons">
                {icons && (
                  <span
                    className="pi pi-ellipsis-v card-icon"
                    onClick={() => {
                      setIsShown(!isShown)
                    }}
                    role="generic"
                  />
                )}
                {isShown && <MenuCard cardIcon={icons} />}
              </div>
            </div>
          )}
          <>
            <div className="card-body">
              <span className="subHeader">{subHeader}</span>
              <div
                className={
                  hideBodyOnExpandedContent && isExpanded
                    ? "body-hidden"
                    : "body-visible"
                }
              >
                {" "}
                {body}
              </div>
            </div>
          </>
          {footer && <div className="footer"> {footer}</div>}
          <SlideDown className="slide-down" closed={!isExpanded}>
            {isExpanded && expandedContent}
          </SlideDown>
          {expandedContent && (
            <IconContainer margin="0" width="100%" className="expand-section">
              {!isExpanded && (
                <BiChevronDown
                  onClick={() => {
                    setIsExpanded(true)
                    setIsHeightLocked(false)
                    if (onExpand) onExpand()
                  }}
                  size="24px"
                  className="pi-angle-down"
                />
              )}
              {isExpanded && (
                <BiChevronUp
                  size="24px"
                  onClick={() => {
                    setIsExpanded(false)
                    setIsShown(false)
                    lockHeight()
                  }}
                  className="pi-angle-up"
                />
              )}
            </IconContainer>
          )}
        </div>
      </div>
    </StyledSimpleCard>
  )
}

export default SimpleCard
