import "./wdyr"
import React from "react"
import ReactDOM from "react-dom"
import ThemeProvider from "theme"
import App from "App"
import { AuthenticationProvider } from "stores"
import "primereact/resources/primereact.min.css"
import "primeicons/primeicons.css"
import "primeflex/primeflex.css"
import "./theme/e-bateTheme.css"

// Import translations
import "i18n"

/*
  ThemeProvider wraps the entire app
  This means we never have a component without
  access to the theme context
*/

ReactDOM.render(
  <ThemeProvider>
    <AuthenticationProvider>
      <App />
    </AuthenticationProvider>
  </ThemeProvider>,
  document.getElementById("root")
)
