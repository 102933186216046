import EbateAxios from "queries/ebateAxios"
import { ValidationResultModel } from "components/models"
import { AxiosResponse } from "axios"
import { UsersGridRequest } from "components/pages/maintanance/users/userList/userList.models"
import { PortalUsersGridDataResponse } from "components/pages/collaboration/portalUserManagement/PortalUserManagementList/PortalUserManagementList.models"
import {
  UserEmailResendRequestModel,
  UserFullInfoModel,
} from "components/pages/maintanance/users/userswizard/user.models"
import {
  CreatePortalUserRequest,
  PortalUserEmailResendRequestModel,
  UpdatePortalUserRequest,
} from "components/pages/collaboration/portalUserManagement/PortalUserManagementWizard/portalUser.model"

const portalUserUrl = "/api/PortalUser"

export const deletePortalUser = async (
  userId: string
): Promise<AxiosResponse<ValidationResultModel>> => {
  const url = `${portalUserUrl}/DeletePortalUser/${userId}`
  const { AxiosDelete } = EbateAxios()

  return AxiosDelete<ValidationResultModel>(url)
}

export const getPortalUsersGridData = async (
  request: UsersGridRequest,
  companyId: number | undefined
) => {
  let url = `${portalUserUrl}/GetPortalUsersGridData`

  if (companyId) {
    url += `/${companyId}`
  }
  const { AxiosPost } = EbateAxios()

  return AxiosPost<UsersGridRequest, PortalUsersGridDataResponse>(url, request)
}

export const getPortalUserById = async (userId: string) => {
  const url = `${portalUserUrl}/GetPortalUserById/${userId}`
  const { AxiosGet } = EbateAxios()

  return AxiosGet<UserFullInfoModel>(url)
}

export const createPortalUser = async (request: CreatePortalUserRequest) => {
  const url = `${portalUserUrl}/CreatePortalUser`

  const { AxiosPost } = EbateAxios()

  return AxiosPost<CreatePortalUserRequest, ValidationResultModel>(url, request)
}

export const updatePortalUser = async (
  request: UpdatePortalUserRequest,
  companyId: number
) => {
  const url = `${portalUserUrl}/UpdatePortalUser/${companyId}`

  const { AxiosPut } = EbateAxios()

  return AxiosPut<UpdatePortalUserRequest, any>(url, request)
}

export const resendPortalEmail = async (
  request: UserEmailResendRequestModel | PortalUserEmailResendRequestModel
) => {
  const url = `${portalUserUrl}/ResendPortalEmail`

  const { AxiosPost } = EbateAxios()

  return AxiosPost<
    UserEmailResendRequestModel | PortalUserEmailResendRequestModel,
    ValidationResultModel
  >(url, request)
}
