import { SSOInfoModel } from "components/library/auth/sso.models"
import EbateAxios from "queries/ebateAxios"

const ssoUrl = "/api/SSO"

export const silentAuth = async (token) => {
  const url = `${ssoUrl}/SSOSilentLogin`
  const { AxiosPostSilentLogin } = EbateAxios()

  return AxiosPostSilentLogin<any, any>(url, token)
}

export const ssoInfo = async () => {
  const url = `${ssoUrl}/SSOInfo`
  const { AxiosGet } = EbateAxios()

  return AxiosGet<SSOInfoModel>(url)
}
