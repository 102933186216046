import { AxiosResponse } from "axios"
import { ValidationResultModel } from "components/models"
import { UnlinkLinkedAccountRequest } from "components/pages/maintanance/tradingGroup/linkedAccountDelete/linkedAccountDelete.models"
import { LinkedAccountAddRequest } from "components/pages/maintanance/tradingGroup/linkedAccountForm/linkedAccountForm.models"
import {
  LinkedAccountGridFilterRequest,
  LinkedAccountsGridFilterResponse,
} from "components/pages/maintanance/tradingGroup/linkedAccountList/linkedAccountList.models"
import { TradingGroupDetailModel } from "components/pages/maintanance/tradingGroup/tradingGroupDetail/tradingGroupDetail.models"
import {
  TradingGroupGridFilterRequest,
  TradingGroupGridResponse,
} from "components/pages/maintanance/tradingGroup/tradingGroupList/tradingGroupList.models"
import EbateAxios from "queries/ebateAxios"

const tradingGroupUrl = "/api/tradingGroup"

export const addAccountsToTradingGroup = async (
  data: LinkedAccountAddRequest
) => {
  const url = `${tradingGroupUrl}/AddAccountsToTradingGroup`
  const { AxiosPost } = EbateAxios()

  return AxiosPost<LinkedAccountAddRequest, ValidationResultModel>(url, data)
}

export const deleteLinkedAccount = async (
  id?: number
): Promise<AxiosResponse<ValidationResultModel>> => {
  const url = `${tradingGroupUrl}/DeleteLinkedAccount/${id}`
  const { AxiosPut } = EbateAxios()
  return AxiosPut<any, ValidationResultModel>(url, undefined)
}

export const getLinkedAccountsGridData = async (
  data: LinkedAccountGridFilterRequest
) => {
  const url = `${tradingGroupUrl}/GetLinkedAccountsGridData`

  const { AxiosPost } = EbateAxios()

  return AxiosPost<
    LinkedAccountGridFilterRequest,
    LinkedAccountsGridFilterResponse
  >(url, data)
}

export const getTradingGroupById = async (tradingGroupId: number) => {
  const url = `${tradingGroupUrl}/GetTradingGroupById/${tradingGroupId}`
  const { AxiosGet } = EbateAxios()

  return AxiosGet<TradingGroupDetailModel>(url)
}

export const getTradingGroupGridData = async (
  data: TradingGroupGridFilterRequest
) => {
  const url = `${tradingGroupUrl}/GetTradingGroupGridData`

  const { AxiosPost } = EbateAxios()

  return AxiosPost<TradingGroupGridFilterRequest, TradingGroupGridResponse>(
    url,
    data
  )
}

export const unlinkLinkedAccount = async (
  data: UnlinkLinkedAccountRequest
): Promise<AxiosResponse<ValidationResultModel>> => {
  const url = `${tradingGroupUrl}/UnlinkLinkedAccount`

  const { AxiosPut } = EbateAxios()
  return AxiosPut<UnlinkLinkedAccountRequest, ValidationResultModel>(url, data)
}
