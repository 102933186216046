import React, { useState } from "react"
import { OptionModel } from "components/helpers"
import { ValidationMessage, MultiSelect } from "components/library"
import { useTranslation } from "react-i18next"
import { useUserStructure } from "./hook"

interface Props {
  userStructureId: number
  errorMessage?: string
  displayValidations?: boolean
  fieldName: string
  heading: string
  required?: boolean
  onChange: (e: any) => void
  selectedItems: any[]
}

export const UserStructureMultiselect: React.FC<Props> = ({
  userStructureId,
  errorMessage,
  displayValidations,
  fieldName,
  heading,
  required,
  onChange,
  selectedItems,
}) => {
  const { t } = useTranslation()
  const [searchTimeout, setSearchTimeout] = useState<any>()
  const [query, setQuery] = useState<string>("")
  const userStructureQuery = useUserStructure(userStructureId, query)
  const [userStructureList, setUserStructureList] = useState<OptionModel[]>([])

  const [userStructuresSelected, setUserStructuresSelected] = useState<
    OptionModel[]
  >(
    selectedItems
      ? selectedItems.map((item) => ({
          value: item,
          title: item,
          hidden: true,
        }))
      : []
  )
  const textChanged = (event: any) => {
    clearTimeout(searchTimeout)
    setSearchTimeout(
      setTimeout(() => {
        setQuery(event.filter)
      }, 500)
    )
  }

  const getSelectedUserStructureValue = (ids: string[]) => {
    const productSelectedButNotInList = ids
      .filter((p) => !userStructureList.map((c) => c.value).includes(p))
      ?.map((c) => ({
        value: c,
        title:
          userStructuresSelected.find((psi) => psi.value === c)?.title ?? c,
        hidden: true,
      }))
    const options = userStructureList.concat(productSelectedButNotInList)
    setUserStructureList(options)

    const selectedItems = ids.map((c) => ({
      value: c,
      title:
        userStructureList?.find((aps) => aps.value === c)?.title ??
        userStructuresSelected.find((psi) => psi.value === c)?.title ??
        c,
      hidden: true,
    }))
    return selectedItems
  }

  React.useEffect(() => {
    if (userStructureQuery.data && userStructureQuery.data.length > 0) {
      const userStructureSelectedButNotInList = userStructuresSelected
        .filter(
          (us) =>
            !userStructureQuery.data.map((u) => u.value).includes(us.value)
        )
        ?.map((us) => ({
          value: us.value,
          title: us.title,
          hidden: true,
        }))

      const userStructuresTemp = userStructureQuery.data.concat(
        userStructureSelectedButNotInList
      )
      setUserStructureList(userStructuresTemp)
    }
  }, [userStructureQuery.data])
  return (
    <>
      <MultiSelect
        options={userStructureList}
        required={required}
        optionLabel="title"
        optionValue="value"
        name={fieldName}
        heading={heading}
        value={userStructureList?.length > 0 ? selectedItems : []}
        onChange={(e) => {
          onChange(e)
          setUserStructuresSelected(getSelectedUserStructureValue(e.value))
        }}
        onFilter={(e) => {
          textChanged(e)
        }}
        placeholder={
          userStructureQuery.data && userStructureQuery.data?.length < 1
            ? t("no-records-found")
            : t("select-options")
        }
      />
      {errorMessage && displayValidations && (
        <ValidationMessage
          messageError={errorMessage}
          showMessage={
            displayValidations &&
            errorMessage !== undefined &&
            errorMessage !== null &&
            errorMessage.length > 0
          }
        />
      )}
    </>
  )
}
