import { OptionApi } from "components/helpers"
import {
  DocumentTabGridFilterRequest,
  DocumentTabGridResponse,
} from "components/library/documentTab"
import { PackageDetailsResponse } from "components/pages/pricemanagement/packages"
import {
  PackageGridRequest,
  PackageGridResponse,
} from "components/pages/pricemanagement/packages/packageList/package.models"
import {
  DealsByCompaniesSelectionRequest,
  PackagesByCompanySelectionResponse,
} from "components/pages/pricemanagement/rebates/rebateCopy/rebateCopy.models"
import EbateAxios from "queries/ebateAxios"
import { PackageRequestAdd } from "components/pages/pricemanagement/packages/packagewizard/packageWizardModels"
import { ValidationResultModel } from "components/models"
import { UpdatePackageRequest } from "components/pages/pricemanagement/packages/packageDetails/packageDetails.apiModel"

const packageUrl = "/api/Package"

export const getPackagesByCompanySelection = async (
  data: DealsByCompaniesSelectionRequest
) => {
  const { AxiosPost } = EbateAxios()

  const url = `${packageUrl}/GetPackagesByCompanySelection`
  return AxiosPost<
    DealsByCompaniesSelectionRequest,
    PackagesByCompanySelectionResponse[]
  >(url, data)
}

export const getPackageDetails = async (id: number) => {
  const { AxiosGet } = EbateAxios()

  const url = `${packageUrl}/GetPackageDetails/${id}`
  return AxiosGet<PackageDetailsResponse>(url)
}

export const getPackageGridData = async (data: PackageGridRequest) => {
  const { AxiosPost } = EbateAxios()

  const url = `${packageUrl}/GetPackageGridData`
  return AxiosPost<PackageGridRequest, PackageGridResponse>(url, data)
}

export const getPackageStatusCombo = async () => {
  const { AxiosGet } = EbateAxios()

  const url = `${packageUrl}/GetPackageStatusCombo`
  return AxiosGet<OptionApi[]>(url)
}

export const getPackageFileGridData = async (
  data: DocumentTabGridFilterRequest
) => {
  const { AxiosPost } = EbateAxios()

  const url = `${packageUrl}/GetPackageFileGridData`
  return AxiosPost<DocumentTabGridFilterRequest, DocumentTabGridResponse>(
    url,
    data
  )
}

export const deletePackage = async (id: number) => {
  const { AxiosPut } = EbateAxios()

  const url = `${packageUrl}/DeletePackage/${id}`
  return AxiosPut<any, ValidationResultModel>(url, {})
}

export const addPackage = async (data: PackageRequestAdd) => {
  const { AxiosPost } = EbateAxios()

  const url = `${packageUrl}/AddPackage`
  return AxiosPost<PackageRequestAdd, ValidationResultModel>(url, data)
}

export const updatePackage = async (data: UpdatePackageRequest) => {
  const { AxiosPut } = EbateAxios()

  const url = `${packageUrl}/UpdatePackage`
  return AxiosPut<UpdatePackageRequest, ValidationResultModel>(url, data)
}
