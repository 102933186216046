import { OptionModel } from "components/helpers"
import { useEffect, useState } from "react"
import {
  ProductGridDataResponse,
  ProductGridRequest,
} from "components/pages/maintanance/product/productList/product.models"
import { getProductGridData } from "queries"

export const useProductDropdown = (productFilter: ProductGridRequest) => {
  const [showSpinner, setShowSpinner] = useState<boolean>(true)
  const [isReload, setIsReload] = useState<boolean>(false)
  const [products, setProducts] = useState<OptionModel[]>([])
  const [hasMore, setHasMore] = useState<boolean>(false)

  const onCompleted = (res: ProductGridDataResponse) => {
    if (!res) return
    const productsFromApi = [...res.productGridItems].map((x) => ({
      value: x.id,
      title: `(${x.productCode}) ${x.description}`,
    }))
    if (isReload) {
      setIsReload(false)
      if (productsFromApi && productsFromApi.length > 0) {
        setProducts(productsFromApi)
      }
    } else if (productsFromApi) {
      const ids = new Set(products.map((d) => d.value))
      const mergedProducts = [
        ...products,
        ...productsFromApi.filter((d) => !ids.has(d.value)),
      ]
      setProducts(mergedProducts)
    }
    setHasMore(res.numberOfPages > productFilter.currentPage)
  }

  const fetchProduct = async () => {
    const response = await getProductGridData(productFilter)

    setShowSpinner(false)
    if (response?.status === 200) {
      onCompleted(response.data)
    }
  }

  useEffect(() => {
    fetchProduct()
  }, [productFilter])

  return { products, hasMore, setIsReload, showSpinner, setShowSpinner }
}

export const useProductDropdownProductCode = (
  productFilter: ProductGridRequest
) => {
  const [showSpinner, setShowSpinner] = useState<boolean>(true)
  const [isReload, setIsReload] = useState<boolean>(false)
  const [products, setProducts] = useState<OptionModel[]>([])
  const [hasMore, setHasMore] = useState<boolean>(false)
  const onCompleted = (res: ProductGridDataResponse) => {
    if (!res) return
    const products = [...res.productGridItems].map((x) => ({
      value: x.productCode,
      title: `(${x.productCode}) ${x.description} `,
    }))
    if (isReload) {
      setIsReload(false)
      if (products && products.length > 0) {
        setProducts(products)
      }
    } else if (products) {
      setProducts((previousProducts: OptionModel[]) => [
        ...previousProducts,
        ...products,
      ])
    }
    setHasMore(res.numberOfPages > productFilter.currentPage)
  }

  const fetchProduct = async () => {
    const response = await getProductGridData(productFilter)

    setShowSpinner(false)
    if (response?.status === 200) {
      onCompleted(response.data)
    }
  }

  useEffect(() => {
    fetchProduct()
  }, [productFilter])

  return { products, hasMore, setIsReload, showSpinner, setShowSpinner }
}
