import React from "react"
import { Switch, Route, Redirect, useRouteMatch } from "react-router-dom"
import { SubNav } from "components/library"
import { StyledRouteManager } from "./Styled"
/*
  The RouteManager takes an array of route objects
  passed via the 'views' prop

  Route objects are structured as follows:
  { 
    route: "<Path>", 
    Component: <The Component to be rendered on this route>,
    exact: <True or false>    
  }

  e.g: 
  { route: "/", Component: Home, exact: true }

  To Lazy load a route, simply use a React.lazy import in place of the reference to your component

  e.g:
  { route: "/", Component: React.lazy(() => import("./Home")), exact: true }
*/

const RouteManager = ({ views, children, subNav, ...rest }) => {
  const route = useRouteMatch()

  return (
    <>
      <StyledRouteManager {...rest}>
        {subNav ? <SubNav links={views} border-top="0" {...rest} /> : null}
      </StyledRouteManager>
      {children || null}
      <Switch>
        {views.map(
          ({
            location,
            dropdownRoutes,
            Component,
            fallbackLocation,
            exact = false,
          }) => {
            if (dropdownRoutes) {
              return dropdownRoutes.map(
                ({ location, Component, exact = false }) => (
                  <Route
                    path={`${route.path === "/" ? "" : route.path}${location}`}
                    exact={exact}
                    key={location}
                    component={Component}
                  />
                )
              )
            }

            if (!fallbackLocation)
              return (
                <Route
                  path={`${route.path === "/" ? "" : route.path}${location}`}
                  exact={exact}
                  key={location}
                  component={Component}
                />
              )

            return (
              <Redirect
                key={`fb_${{ fallbackLocation }}`}
                to={fallbackLocation}
              />
            )
          }
        )}
      </Switch>
    </>
  )
}

export default RouteManager
