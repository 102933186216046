import React from "react"
import { Chip, FlexContainer } from "components/library"
import { ComponentTypes, dateUtils, numberFormatter } from "components/helpers"
import { StyledDetailsForm } from "./styled"

const RenderControl = ({ field, isEditable }) => {
  const { value, componenttype } = field.props
  if (componenttype === ComponentTypes.span) {
    return (
      <div className="field-not-editable" data-testid={field.props.name}>
        {field}
      </div>
    )
  }
  if (componenttype === ComponentTypes.Checkbox) {
    return <div className="field-not-editable">{field}</div>
  }
  if (componenttype === ComponentTypes.DatePicker && !isEditable) {
    return (
      <div className="field-not-editable">
        <span data-testid={field.props.name}>
          {dateUtils.formatDateToLocale(value)}
        </span>
      </div>
    )
  }
  if (componenttype === ComponentTypes.Dropdown && !isEditable) {
    const element = field.props.options?.find((e) => e.value === value)
    const valueItem = element ? element.title : value

    return (
      <div className="field-not-editable">
        <span data-testid={field.props.name}>{valueItem}</span>
      </div>
    )
  }
  if (componenttype === ComponentTypes.CompanyDropdown && !isEditable) {
    return (
      <div className="field-not-editable">
        <span data-testid={field.props.name}>
          {field.props?.companySelected?.title}
        </span>
      </div>
    )
  }

  if (componenttype === ComponentTypes.InputNumber && !isEditable) {
    return (
      <div className="field-not-editable">
        <span data-testid={field.props.name}>
          {(field.props.decimalnumberlabel !== null &&
          field.props.decimalnumberlabel !== undefined
            ? numberFormatter
                .customNumberFormatter(field.props.decimalnumberlabel)
                .format(value)
            : numberFormatter.numberFormatter.format(value)) +
            (field.props.suffix !== null && field.props.suffix !== undefined
              ? field.props.suffix
              : "")}
        </span>
      </div>
    )
  }

  if (componenttype === ComponentTypes.MultiSelect && !isEditable) {
    const valueItem =
      value && value.length > 0
        ? value.map((val) => {
            const item = field.props.options.find((e) => e.optionValue === val)
            return item ? item.optionLabel : val
          })
        : []
    return (
      <div className="multiselect-not-editable">
        {valueItem.map((val: string, index: number) => (
          <Chip key={index} text={val} />
        ))}
      </div>
    )
  }

  if (!isEditable) {
    return (
      <div className="field-not-editable">
        <span data-testid={field.props.name}>{value}</span>
      </div>
    )
  }

  return <div>{field}</div>
}

const DetailsForm = ({ isEditable, children }) =>
  children.map((field, index) => {
    const { label, name, hidden, required } = field.props
    const key = `${name}-${index}`
    return (
      <div key={key}>
        {!hidden && (
          <StyledDetailsForm>
            <FlexContainer align="center">
              <div
                className={`editor-detail editor-detail-label ${
                  isEditable ? "editor-detail-label-edit" : "field-not-editable"
                }`}
              >
                <span>{label}</span>
                {required && <span className="p-error"> *</span>}
              </div>
              <div className="editor-detail editor-detail-item">
                <RenderControl field={field} isEditable={isEditable} />
              </div>
            </FlexContainer>
          </StyledDetailsForm>
        )}
      </div>
    )
  })

export default DetailsForm
