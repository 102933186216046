import { ValidationResultModel } from "components/models"
import {
  AgreementRequestGridResponse,
  UserAgreementGridFilterRequest,
} from "components/pages/pricemanagement/approvals/approvalAgreement/approvalAgreement.model"
import {
  AmendRequestGridResponse,
  UserAmendGridFilterRequest,
} from "components/pages/pricemanagement/approvals/approvalAmend/approvalAmend.model"
import {
  ClaimPaymentApprovalRequest,
  ClaimPaymentApprovalResponse,
} from "components/pages/pricemanagement/approvals/approvalClaimPayment/approvalClaimPayments.model"
import {
  FileGridFilterRequestType,
  RequestFileGridDataResponse,
} from "components/pages/pricemanagement/approvals/approvalFiles/approvalFiles.models"
import {
  ApproveRequestGatewayRequest,
  RejectRequestGatewayRequest,
} from "components/pages/pricemanagement/approvals/approvalForms/request.models"
import {
  RateApprovalRequest,
  RequestRebateRateOverviewGridResponse,
} from "components/pages/pricemanagement/approvals/approvalRate/approvalRateList/approvalRateList.models"

import EbateAxios from "queries/ebateAxios"

const approvalUrl = "/api/approval"

export const getUserAgreementRequestGridData = async (
  data: UserAgreementGridFilterRequest
) => {
  const url = `${approvalUrl}/GetUserAgreementRequestGridData`
  const { AxiosPost } = EbateAxios()
  return AxiosPost<
    UserAgreementGridFilterRequest,
    AgreementRequestGridResponse
  >(url, data)
}

export const getUserAmendAgreementRequestGridData = async (
  data: UserAmendGridFilterRequest
) => {
  const url = `${approvalUrl}/GetUserAmendAgreementRequestGridData`
  const { AxiosPost } = EbateAxios()
  return AxiosPost<UserAmendGridFilterRequest, AmendRequestGridResponse>(
    url,
    data
  )
}

export const getPaymentsClaimsRequestsGridData = async (
  data: ClaimPaymentApprovalRequest
) => {
  const url = `${approvalUrl}/GetPaymentsClaimsRequestsGridData`
  const { AxiosPost } = EbateAxios()
  return AxiosPost<ClaimPaymentApprovalRequest, ClaimPaymentApprovalResponse>(
    url,
    data
  )
}

export const getRequestsFileGridData = async (
  data: FileGridFilterRequestType
) => {
  const url = `${approvalUrl}/GetRequestsFileGridData`
  const { AxiosPost } = EbateAxios()
  return AxiosPost<FileGridFilterRequestType, RequestFileGridDataResponse>(
    url,
    data
  )
}

export const getRequestsRebateRateGridData = async (
  data: RateApprovalRequest
) => {
  const url = `${approvalUrl}/GetRequestsRebateRateGridData`
  const { AxiosPost } = EbateAxios()
  return AxiosPost<RateApprovalRequest, RequestRebateRateOverviewGridResponse>(
    url,
    data
  )
}

export const getRebateRatesByRequest = async (requestId: number) => {
  const url = `${approvalUrl}/GetRebateRatesByRequest/${requestId}`
  const { AxiosGet } = EbateAxios()
  return AxiosGet<any[]>(url)
}

export const approveRequest = async (data: ApproveRequestGatewayRequest) => {
  const url = `${approvalUrl}/ApproveRequest`
  const { AxiosPost } = EbateAxios()
  return AxiosPost<ApproveRequestGatewayRequest, ValidationResultModel>(
    url,
    data
  )
}

export const rejectRequest = async (data: RejectRequestGatewayRequest) => {
  const url = `${approvalUrl}/RejectRequest`
  const { AxiosPost } = EbateAxios()
  return AxiosPost<RejectRequestGatewayRequest, ValidationResultModel>(
    url,
    data
  )
}

export const processSendRemittanceAdvice = async (requestId: number) => {
  const url = `${approvalUrl}/ProcessSendRemittanceAdvice/${requestId}`
  const { AxiosPost } = EbateAxios()
  return AxiosPost<any, ValidationResultModel>(url, {})
}
