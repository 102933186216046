import { useEffect, useState } from "react"
import { getTenantCurrencies } from "queries"
import { CurrencyApiModel } from "components/pages/maintanance/currency/currencyList/currencyList.models"
import { OptionApi } from "components/helpers"

const useCurrencies = () => {
  const [currencies, setCurrencies] = useState<OptionApi[] | []>([])
  const [currencyLoadedData, setLoadedData] = useState<boolean>(false)

  const onCompleted = (data: CurrencyApiModel[]) => {
    setLoadedData(true)
    setCurrencies([
      ...data.map((d) => ({ id: d.id, name: d.currencyName } as OptionApi)),
    ])
  }

  const fetchTenantCurrencies = async () => {
    const response = await getTenantCurrencies()

    if (response?.status === 200) {
      onCompleted(response.data)
    }
  }

  useEffect(() => {
    fetchTenantCurrencies()
  }, [])

  return { currencies, currencyLoadedData }
}

export default useCurrencies
