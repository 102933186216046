import { ValidationResultModel } from "components/models"
import {
  DocumentApiModel,
  DocumentUpdateRequestModel,
} from "components/pages/collaboration/document/documentForm/DocumentModels"
import {
  DocumentGridRequest,
  DocumentResponse,
} from "components/pages/collaboration/document/documentList/document.models"
import EbateAxios from "queries/ebateAxios"

const documentUrl = "/api/Document"

export const doSaveDocument = async (data: any) => {
  const url = `${documentUrl}/UploadDocument`
  const { AxiosPost } = EbateAxios()

  return AxiosPost<any, ValidationResultModel>(url, data)
}

export const getDocumentGridData = async (data: DocumentGridRequest) => {
  const url = `${documentUrl}/GetDocumentGridData`

  const { AxiosPost } = EbateAxios()

  return AxiosPost<DocumentGridRequest, DocumentResponse>(url, data)
}

export const getDocumentById = async (fileId: string) => {
  const url = `${documentUrl}/GetDocumentById/${fileId}`
  const { AxiosGet } = EbateAxios()

  return AxiosGet<DocumentApiModel>(url)
}

export const deleteDocument = async (fileId: string) => {
  const url = `${documentUrl}/DeleteDocument/${fileId}`
  const { AxiosPut } = EbateAxios()

  return AxiosPut<any, ValidationResultModel>(url, {})
}

export const updateDocument = async (data: DocumentUpdateRequestModel) => {
  const url = `${documentUrl}/UpdateDocument`
  const { AxiosPut } = EbateAxios()

  return AxiosPut<DocumentUpdateRequestModel, ValidationResultModel>(url, data)
}
