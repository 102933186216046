import { OptionApi } from "components/helpers"
import { WorkflowAuditTrailsRequest } from "components/pages/maintanance/workflowAuditTrail/workflowAuditTrail.models"
import EbateAxios from "queries/ebateAxios"

const workflowAuditUrl = "/api/workflowAudit"

export const getWorkflowTypesForAudit = async () => {
  const { AxiosGet } = EbateAxios()

  return AxiosGet<OptionApi[]>(`${workflowAuditUrl}/GetWorkflowTypesForAudit`)
}

export const getAgreementsInWorkflowAuditTrails = async (
  workflowType: number
) => {
  const { AxiosGet } = EbateAxios()

  return AxiosGet<OptionApi[]>(
    `${workflowAuditUrl}/getAgreementsInWorkflowAuditTrails/${workflowType}`
  )
}

export const getWorkflowAuditTrails = async (
  data: WorkflowAuditTrailsRequest
) => {
  const { AxiosPost } = EbateAxios()

  const url = `${workflowAuditUrl}/GetWorkflowAuditTrails`

  return AxiosPost<WorkflowAuditTrailsRequest, Blob>(
    url,
    data,
    undefined,
    2 * 60 * 1000
  )
}
