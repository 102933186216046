import React from "react"
import { IconButton, IconContainer, FlexContainer } from "components/library"
import { StyledHeaderForm } from "./styled"

interface Props {
  className?: string
  headerDetail: any
  isEditable: boolean
  isExpanded: boolean
  setEditable: () => void
  onCancel?: () => void
  onSave: () => void
  isReadOnly?: boolean
  onSendToApproval?: () => void
  onAgreementChanges?: () => void
  onAgreementChangesRevert?: () => void
}

const HeaderForm: React.FC<Props> = ({
  className,
  headerDetail,
  isEditable,
  isExpanded,
  onCancel,
  setEditable,
  onSave,
  onSendToApproval,
  isReadOnly,
  onAgreementChanges,
  onAgreementChangesRevert,
}) => (
  <StyledHeaderForm className={className}>
    <FlexContainer className="edit-links">
      <IconContainer className="icon-container">
        {isExpanded && isEditable && (
          <IconContainer>
            <IconButton
              icon="pi pi-save"
              onClick={() => {
                onSave()
              }}
              name="save"
            />
            <IconButton
              icon="pi pi-times"
              onClick={() => {
                if (onCancel !== undefined) {
                  onCancel()
                }
                setEditable()
              }}
              name="cancel"
            />
          </IconContainer>
        )}
        {isExpanded && !isEditable && !isReadOnly && (
          <IconButton
            icon="pi pi-pencil"
            onClick={() => {
              setEditable()
            }}
            name="edit"
          />
        )}
        {onSendToApproval && !isReadOnly && (
          <IconButton
            icon="pi pi-arrow-right"
            onClick={() => {
              if (onSendToApproval !== undefined) {
                onSendToApproval()
              }
            }}
            name="sendToApproval"
          />
        )}
        {onAgreementChanges && !isEditable && (
          <IconButton
            icon="pi pi-info"
            onClick={() => {
              if (onAgreementChanges !== undefined) {
                onAgreementChanges()
              }
            }}
            name="agreementChanges"
          />
        )}
        {onAgreementChangesRevert && !isEditable && (
          <IconButton
            icon="pi pi-undo"
            onClick={() => {
              if (onAgreementChangesRevert !== undefined) {
                onAgreementChangesRevert()
              }
            }}
            name="agreementChangesRevert"
          />
        )}
      </IconContainer>
      <h1>{headerDetail}</h1>
    </FlexContainer>
  </StyledHeaderForm>
)

export default HeaderForm
