import {
  ReviewTradeAgreementFileRequest,
  TradeAgreementGridRequest,
  TradeAgreementGridResponse,
} from "components/pages/collaboration/tradeAgreements/tradeAgreementList/tradeAgreement.models"
import EbateAxios from "queries/ebateAxios"
import { PortalTradeAgreementCustomFileRequest } from "components/library/tradeAgreementsTab/tradeAgreementForm/tradeAgreementForm.model"
import { ValidationResultModel } from "components/models"

const tradeAgreementsUrl = "/api/TradeAgreements"

export const getTradeAgreementByCompanyGridData = async (
  data: TradeAgreementGridRequest
) => {
  const url = `${tradeAgreementsUrl}/GetTradeAgreementFilesByCompanyGridData`
  const { AxiosPost } = EbateAxios()
  return AxiosPost<TradeAgreementGridRequest, TradeAgreementGridResponse>(
    url,
    data
  )
}

export const reviewTradeAgreementFile = async (
  data: ReviewTradeAgreementFileRequest
) => {
  const url = `${tradeAgreementsUrl}/ReviewTradeAgreementFile`
  const { AxiosPut } = EbateAxios()
  return AxiosPut<ReviewTradeAgreementFileRequest, ValidationResultModel>(
    url,
    data
  )
}

export const addNewAgreementFileFromExternalSource = async (
  data: PortalTradeAgreementCustomFileRequest
) => {
  const url = `${tradeAgreementsUrl}/AddNewAgreementFileFromExternalSource`
  const { AxiosPost } = EbateAxios()
  return AxiosPost<
    PortalTradeAgreementCustomFileRequest,
    ValidationResultModel
  >(url, data)
}
