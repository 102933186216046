const ComponentTypes = Object.freeze({
  InputText: 1,
  InputNumber: 2,
  Checkbox: 3,
  DatePicker: 4,
  span: 5,
  Dropdown: 6,
  Switch: 7,
  MultiSelect: 8,
  CompanyDropdown: 9,
})

export default ComponentTypes
