import EbateAxios from "queries/ebateAxios"
import { OptionApi } from "components/helpers"
import { ValidationResultModel } from "components/models"
import { AxiosResponse } from "axios"
import { UserPermissionsApiResponse } from "components/hooks/fetch"
import {
  UsersGridDataResponse,
  UsersGridRequest,
} from "components/pages/maintanance/users/userList/userList.models"
import {
  CreateUserRequest,
  UpdateUserRequest,
  UserEmailResendRequestModel,
  UserFullInfoModel,
} from "components/pages/maintanance/users/userswizard/user.models"
import { PortalUserEmailResendRequestModel } from "components/pages/collaboration/portalUserManagement/PortalUserManagementWizard/portalUser.model"
import { UserStructureFilter } from "components/library"

const userUrl = "/api/User"

export const getAllUsersCombo = async () => {
  const url = `${userUrl}/GetAllUsersCombo`
  const { AxiosGet } = EbateAxios()

  return AxiosGet<OptionApi[]>(url)
}

export const deleteUser = async (
  userId: string
): Promise<AxiosResponse<ValidationResultModel>> => {
  const url = `${userUrl}/DeleteUser/${userId}`
  const { AxiosDelete } = EbateAxios()

  return AxiosDelete<ValidationResultModel>(url)
}

export const getUserPermissionOptions = async () => {
  const url = `${userUrl}/GetUserPermissionOptions`
  const { AxiosGet } = EbateAxios()

  return AxiosGet<UserPermissionsApiResponse>(url)
}

export const getNormalUsersGridData = async (request: UsersGridRequest) => {
  const url = `${userUrl}/GetNormalUsersGridData`
  const { AxiosPost } = EbateAxios()

  return AxiosPost<UsersGridRequest, UsersGridDataResponse>(url, request)
}

export const getUserById = async (userId: string) => {
  const url = `${userUrl}/GetUserById/${userId}`
  const { AxiosGet } = EbateAxios()

  return AxiosGet<UserFullInfoModel>(url)
}

export const createUser = async (request: CreateUserRequest) => {
  const url = `${userUrl}/CreateUser`

  const { AxiosPost } = EbateAxios()

  return AxiosPost<CreateUserRequest, ValidationResultModel>(url, request)
}

export const updateUser = async (request: UpdateUserRequest) => {
  const url = `${userUrl}/UpdateUser`

  const { AxiosPut } = EbateAxios()

  return AxiosPut<UpdateUserRequest, any>(url, request)
}

export const resendEmail = async (
  request: UserEmailResendRequestModel | PortalUserEmailResendRequestModel
) => {
  const url = `${userUrl}/ResendEmail`

  const { AxiosPost } = EbateAxios()

  return AxiosPost<
    UserEmailResendRequestModel | PortalUserEmailResendRequestModel,
    ValidationResultModel
  >(url, request)
}

export const getUserStructureValues = async (
  userStructureId: number,
  querySearch: string
) => {
  const url =
    querySearch !== undefined && querySearch !== null && querySearch !== ""
      ? `${userUrl}/GetUserStructureValues/${userStructureId}?searchQuery=${querySearch}`
      : `${userUrl}/GetUserStructureValues/${userStructureId}`
  const { AxiosGet } = EbateAxios()

  return AxiosGet<UserStructureFilter>(url)
}
