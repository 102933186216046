import { useContext } from "react"
import { useTranslation } from "react-i18next"
import { TopLevelRoutes, PortalTopLevelRoutes } from "routes"
import { BEConfigurationContext } from "stores"

const useRoutesTranslations = () => {
  const { t } = useTranslation("route")
  const { tenantConfiguration } = useContext(BEConfigurationContext)

  const translateDropdownRoutes = (routes) => {
    const routeTranslated = routes.map((route) => ({
      ...route,
      name: route.name ? t(`${route.name}`) : "",
      location:
        route.location === "reportServerUrl"
          ? tenantConfiguration.reportServerUrl
          : route.location,
    }))

    return routeTranslated
  }
  const translateTopLevelRoutes = () => {
    const topLevelRoutes = TopLevelRoutes.map((topRoute) => ({
      ...topRoute,
      name: topRoute.name ? t(`${topRoute.name}`) : "",
      dropdownRoutes: topRoute.dropdownRoutes
        ? translateDropdownRoutes(topRoute.dropdownRoutes)
        : undefined,
    }))
    return topLevelRoutes
  }
  const translatePortalTopLevelRoutes = () => {
    const topLevelRoutes = PortalTopLevelRoutes.map((topRoute) => ({
      ...topRoute,
      name: topRoute.name ? t(`${topRoute.name}`) : "",
      dropdownRoutes: topRoute?.dropdownRoutes
        ? translateDropdownRoutes(topRoute?.dropdownRoutes)
        : undefined,
    }))
    return topLevelRoutes
  }

  return { translateTopLevelRoutes, translatePortalTopLevelRoutes }
}

export default useRoutesTranslations
