import { ValidationResultModel } from "components/models"
import {
  AccrualsGridRequest,
  AccrualsGridResponse,
  OpenPeriod,
} from "components/pages/finance/accruals/accrualsList/accrual.models"
import EbateAxios from "queries/ebateAxios"

const accrualUrl = "/api/Accrual"

export const getOpenPeriod = async () => {
  const url = `${accrualUrl}/GetOpenPeriod`
  const { AxiosGet } = EbateAxios()

  return AxiosGet<OpenPeriod>(url)
}

export const getAccrualGridData = async (data: AccrualsGridRequest) => {
  const url = `${accrualUrl}/GetAccrualGridData`
  const { AxiosPost } = EbateAxios()

  return AxiosPost<AccrualsGridRequest, AccrualsGridResponse>(url, data)
}

export const finalisePeriodAccruals = async (periodId: number) => {
  const url = `${accrualUrl}/FinalisePeriodAccruals/${periodId}`
  const { AxiosPut } = EbateAxios()

  return AxiosPut<any, ValidationResultModel>(url, {})
}
