import {
  Dropdown,
  FilterValueModel,
  ValidationMessage,
} from "components/library"
import React, { useState } from "react"
import { useCompanies } from "components/hooks"
import { OptionModel } from "components/helpers"
import { useTranslation } from "react-i18next"

interface Props {
  companySelected: FilterValueModel<number>
  companyOnChange: (e: any, description) => void
  errorMessage: string
  displayValidations: boolean
  heading?: string
  required?: boolean
  allCompaniesActive?: boolean
  hidden?: boolean
  componenttype?
  label?: string
  name?: string
}

// To use this component when a company is already selected we have to put the value and the name of the company inside
// the companySelected property
export const CompanyDropdown: React.FC<Props> = ({
  companySelected,
  companyOnChange,
  errorMessage = "",
  displayValidations,
  name,
  heading = "",
  required,
  allCompaniesActive,
  componenttype,
  hidden,
  label,
}) => {
  const { t } = useTranslation(["translation"])

  const [query, setQuery] = useState<string | undefined>("")
  const { companies } = useCompanies(query, allCompaniesActive)

  const [searchTimeout, setSearchTimeout] = useState<any>()

  const [companyList, setCompanyList] = useState<OptionModel[]>([])

  const filterByText = (textToFilter: string) => {
    setQuery(textToFilter)
  }

  const textChanged = (event: any) => {
    if (event.filter !== "" || query === "") {
      clearTimeout(searchTimeout)
      setSearchTimeout(
        setTimeout(() => {
          filterByText(event.filter)
        }, 200)
      )
    }
  }

  const onHideSearchPanel = () => {
    filterByText("")
  }

  React.useEffect(() => {
    if (
      companySelected?.value !== undefined &&
      companySelected?.value !== null &&
      !companies.map((c) => c.value).includes(companySelected?.value)
    ) {
      const options = companies.concat([
        {
          value: companySelected.value,
          title: companySelected.title,
        },
      ])

      setCompanyList(options)
    } else {
      setCompanyList(companies)
    }
  }, [companies])

  return (
    <>
      <Dropdown
        heading={heading}
        options={companyList}
        id="company"
        name={name}
        value={companySelected?.value}
        required={required}
        placeholder="Select option..."
        onChange={(e) => {
          const description = companyList?.find(
            (o) => o.value === e.target.value
          )?.title
          companyOnChange(e, description)
        }}
        onFilter={(e) => {
          textChanged(e)
        }}
        canSearch
        hidden={hidden}
        onHide={() => onHideSearchPanel()}
        filterPlaceholder={t("searchForMoreCompanies")}
        componenttype={componenttype}
        label={label}
      />
      <ValidationMessage
        messageError={errorMessage}
        showMessage={
          displayValidations &&
          errorMessage !== undefined &&
          errorMessage !== null &&
          errorMessage.length > 0
        }
      />
    </>
  )
}
