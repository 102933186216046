import { ebateQueryKeys, useEbateQuery } from "queries/ebateReactQuery"
import {
  CacheTime,
  StaleTime,
} from "queries/ebateReactQuery/ebateReactQuery.models"
import {
  getAggregationLevels,
  getAgreementStatusCombo,
  getClaimTypes,
  getCompanyTypes,
  getDocumentTags,
  getPeriods,
  getRebateCategories,
  getRequestStatuses,
  getUnitOfMeasurements,
} from "queries/common/dropdownData.query"
import { OptionApi, OptionModel, dropdownUtils } from "components/helpers"

export const useDropdownData = () => {
  const documentTagsQuery = useEbateQuery(
    ebateQueryKeys.QUERY_KEY_DOCUMENT_TAGS,
    getDocumentTags,
    {
      cacheTime: CacheTime.long,
      staleTime: StaleTime.medium,
    }
  )

  return { documentTagsQuery }
}

export const useRequestStatuses = () => {
  const mapOptionData = (data: any): OptionModel[] =>
    data &&
    data.data.map((d: OptionApi) => ({
      title: d.name,
      value: d.id,
    }))

  const requestStatuses = useEbateQuery(
    ebateQueryKeys.QUERY_KEY_REQUEST_STATUSES,
    getRequestStatuses,
    {
      select: (data) => mapOptionData(data),
      cacheTime: CacheTime.long,
      staleTime: StaleTime.medium,
    }
  )

  return { requestStatuses }
}

export const useRebateCategories = () => {
  const onCompletedRebateCategories = (data: any) => {
    const rebateCategoriesMapped =
      dropdownUtils.mapToDropDownOptionFromApi(data)
    return [...rebateCategoriesMapped]
  }

  const fetchRebateCategories = async () => {
    const rebateCategoriesResponse = await getRebateCategories()

    if (rebateCategoriesResponse?.status === 200) {
      return onCompletedRebateCategories(rebateCategoriesResponse.data)
    }
    throw new Error(rebateCategoriesResponse.statusText)
  }

  const rebateCategories = useEbateQuery(
    [...ebateQueryKeys.QUERY_KEY_REBATE_CATEGORIES],
    fetchRebateCategories,
    {
      cacheTime: CacheTime.long,
      staleTime: StaleTime.long,
    }
  )
  return rebateCategories
}

export const useCompanyTypes = () => {
  const onCompletedCompanyTypes = (data: any) => {
    const rebateCategoriesMapped =
      dropdownUtils.mapToDropDownOptionFromApi(data)
    return [...rebateCategoriesMapped]
  }

  const fetchCompanyTypes = async () => {
    const companyTypesResponse = await getCompanyTypes()

    if (companyTypesResponse?.status === 200) {
      return onCompletedCompanyTypes(companyTypesResponse.data)
    }
    throw new Error(companyTypesResponse.statusText)
  }

  const companyTypes = useEbateQuery(
    [...ebateQueryKeys.QUERY_KEY_COMPANY_TYPES],
    fetchCompanyTypes,
    {
      cacheTime: CacheTime.long,
      staleTime: StaleTime.long,
    }
  )
  return companyTypes
}

export const useUnitOfMeasurements = () => {
  const onCompletedUOM = (data: any) => {
    const uOMMapped = dropdownUtils.mapToDropDownOptionFromApi(
      data.unitOfMeasurements
    )
    return [...uOMMapped]
  }

  const fetchUOM = async () => {
    const uomResponse = await getUnitOfMeasurements()
    if (uomResponse?.status === 200) {
      return onCompletedUOM(uomResponse.data)
    }
    throw new Error(uomResponse.statusText)
  }

  const unitOfMeasurements = useEbateQuery(
    [...ebateQueryKeys.QUERY_KEY_UNIT_OF_MEASUREMENTS],
    fetchUOM,
    {
      cacheTime: CacheTime.long,
      staleTime: StaleTime.long,
    }
  )
  return unitOfMeasurements
}

export const usePeriods = () => {
  const onCompletedPeriods = (data: any) => {
    const periodsMapped = dropdownUtils.mapToDropDownOptionFromApi(data)
    return [...periodsMapped]
  }

  const fetchPeriods = async () => {
    const periodsResponse = await getPeriods()
    if (periodsResponse?.status === 200) {
      return onCompletedPeriods(periodsResponse.data)
    }
    throw new Error(periodsResponse.statusText)
  }

  const periods = useEbateQuery(
    [...ebateQueryKeys.QUERY_KEY_PERIODS],
    fetchPeriods,
    {
      cacheTime: CacheTime.long,
      staleTime: StaleTime.long,
    }
  )
  return periods
}

export const useAgreementStatus = () => {
  const onCompletedAgreementStatus = (res) => {
    const agreementStatusesFromApi =
      dropdownUtils.mapToDropDownOptionFromApi(res)
    return [...agreementStatusesFromApi]
  }

  const fetchAgreementStatus = async (): Promise<OptionModel[]> => {
    const response = await getAgreementStatusCombo()

    if (response?.status === 200) {
      return onCompletedAgreementStatus(response.data)
    }
    return []
  }

  const agreementStatuses = useEbateQuery(
    [...ebateQueryKeys.QUERY_KEY_AGREEMENT_STATUS_VALUES],
    fetchAgreementStatus,
    {
      cacheTime: CacheTime.long,
      staleTime: StaleTime.long,
    }
  )

  return agreementStatuses
}

export const useAggregationLevels = () => {
  const onCompletedAggregationLevel = (res) => {
    const aggregationLevels = dropdownUtils.mapToDropDownOptionFromApi(res)
    return [...aggregationLevels]
  }

  const fetchAggregationLevels = async (): Promise<OptionModel[]> => {
    const response = await getAggregationLevels()

    if (response?.status === 200) {
      return onCompletedAggregationLevel(response.data)
    }
    return []
  }

  const aggregationLevels = useEbateQuery(
    [...ebateQueryKeys.QUERY_KEY_AGGREGATION_LEVELS],
    fetchAggregationLevels,
    {
      cacheTime: CacheTime.long,
      staleTime: StaleTime.long,
    }
  )

  return aggregationLevels
}

export const useClaimTypes = (enableQuery: boolean) => {
  const onCompletedClaimTypes = (res) => {
    const claimTypesFromApi = dropdownUtils.mapToDropDownOptionFromApi(res)
    return [...claimTypesFromApi]
  }

  const fetchClaimTypes = async (): Promise<OptionModel[]> => {
    const response = await getClaimTypes()

    if (response?.status === 200) {
      return onCompletedClaimTypes(response.data)
    }
    return []
  }

  const claimTypes = useEbateQuery(
    [...ebateQueryKeys.QUERY_KEY_CLAIM_TYPES],
    fetchClaimTypes,
    {
      cacheTime: CacheTime.long,
      staleTime: StaleTime.long,
      enabled: enableQuery,
    }
  )

  return claimTypes
}
