import React from "react"
import { StyledNumberWidget } from "./styled"

interface Props {
  bgcolor: string
  icon: string
  number: string | number
  text: string
}
const NumberWidget: React.FC<Props> = ({ bgcolor, icon, number, text }) => (
  <StyledNumberWidget bgcolor={bgcolor}>
    <div className="nw-body">
      <div className="nw-left">
        <span className="nw-number">{number}</span>
        <span className="nw-text">{text}</span>
      </div>
      <i className={`${icon} nw-icon`} />
    </div>
  </StyledNumberWidget>
)

export default NumberWidget
