import { dropdownUtils, OptionModel } from "components/helpers"
import { getPaymentAdjustmentTypes } from "queries"
import { useEffect, useState } from "react"

const usePaymentadjustmentTypes = () => {
  const [paymentadjustmentTypes, setPaymentadjustmentTypes] = useState<
    OptionModel[] | []
  >([])
  const [loadedData, setLoadedData] = useState<boolean>(false)

  const getPaymentadjustmentTypeValues = async () => {
    try {
      setLoadedData(false)
      const paymentadjustmentTypesResponse = await getPaymentAdjustmentTypes()
      setLoadedData(true)
      if (paymentadjustmentTypesResponse.status === 200) {
        setPaymentadjustmentTypes(
          dropdownUtils.mapToDropDownOptionFromApi(
            paymentadjustmentTypesResponse.data
          )
        )
      }
    } catch (e) {
      setLoadedData(true)
    }
  }
  useEffect(() => {
    getPaymentadjustmentTypeValues()
  }, [])

  return { paymentadjustmentTypes, loadedData }
}

export default usePaymentadjustmentTypes
