import { useState, useEffect } from "react"
import { dateUtils } from "components/helpers"
// eslint-disable-next-line camelcase
import jwt_decode from "jwt-decode"
import Axios from "axios"
import { SessionStorageKeys } from "components/models/sessionStorageKeys"

const useAuthentication = () => {
  const [authToken, setAuthToken] = useState<any>({})
  const [secondsLeft, setSecondsLeft] = useState()
  const [decoded, setDecoded] = useState(null)

  const signOut = () => {
    localStorage.setItem("logout", Date.now().toString())
    sessionStorage.removeItem(SessionStorageKeys.linkDate)
    sessionStorage.removeItem(SessionStorageKeys.unlinkDate)

    setAuthToken({})
    setDecoded(null)
  }

  const storeAuthToken = (jwt, expiresIn) => {
    setSecondsLeft(expiresIn)
    setAuthToken({ token: jwt, expiry: dateUtils.getDateAddSeconds(expiresIn) })
    if (expiresIn) setDecoded(jwt_decode(jwt))
  }

  const getToken = () => authToken.token

  const getAuthorization = () =>
    getToken() !== undefined &&
    getToken().length > 0 &&
    authToken.expiry !== null
  const [isAuthorized, setIsAuthorized] = useState(getAuthorization())

  const getTokenValue = (key) => {
    if (decoded) return decoded[key]
    return null
  }

  const getPermission = (permission) =>
    decoded !== null ? decoded[permission] : undefined

  useEffect(() => {
    if (authToken !== undefined) {
      const authorized = getAuthorization()
      if (isAuthorized !== authorized) {
        setIsAuthorized(authorized)
        Axios.defaults.headers.common.Authorization = authorized
          ? `Bearer ${authToken.token}`
          : ""
      }
    }
  }, [authToken])

  return {
    storeAuthToken,
    getToken,
    isAuthorized,
    signOut,
    secondsLeft,
    setSecondsLeft,
    getTokenValue,
    getPermission,
  }
}

export default useAuthentication
