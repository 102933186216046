import { OptionModel, dropdownUtils } from "components/helpers"
import {
  CrossReferenceExchangeObjectResponse,
  CrossReferencedValueRequest,
  CrossReferencedValuesParam,
} from "components/pages/maintanance/crossReference/crossReferenceList/crossReference.models"
import { ebateQueryKeys, useEbateQuery } from "queries/ebateReactQuery"
import {
  CacheTime,
  StaleTime,
} from "queries/ebateReactQuery/ebateReactQuery.models"
import { getExchangeFormatByMappingId } from "queries/implementation/exchangeFormat.query"
import { getAllData } from "queries/implementation/exchangeMapping.query"
import { getCrossReferencedValues } from "../crossReference.query"

export const useImportNames = () => {
  const fetchImportNames = async (): Promise<OptionModel[]> => {
    const response = await getAllData()

    if (response?.status === 200) {
      const importNamesFromApi = dropdownUtils.mapToDropDownOptionFromApi(
        response.data
      )
      return [...importNamesFromApi]
    }
    return []
  }

  const importNames = useEbateQuery(
    ebateQueryKeys.QUERY_KEY_IMPORT_NAMES,
    fetchImportNames,
    {
      cacheTime: CacheTime.long,
      staleTime: StaleTime.medium,
    }
  )

  return { importNames }
}

export const useDataTypes = (id: number) => {
  const fetchImportNames = async (): Promise<OptionModel[]> => {
    const response = await getExchangeFormatByMappingId(id)

    if (response?.status === 200) {
      const dataTypesFromApi = response.data?.map((option) => ({
        value: option.id,
        title: option.description,
      }))
      return [...dataTypesFromApi]
    }

    return []
  }

  const dataTypes = useEbateQuery(
    [...ebateQueryKeys.QUERY_KEY_DATA_TYPES, id.toString()],
    fetchImportNames,
    {
      cacheTime: CacheTime.long,
      staleTime: StaleTime.medium,
      enabled: id !== null && id !== undefined,
    }
  )

  return { dataTypes }
}

export const useCrossReferenceValues = (data: CrossReferencedValuesParam) => {
  const onCompletedEbateValues = (
    data: CrossReferenceExchangeObjectResponse
  ) => {
    const dataEbateValueFromApi = data.exchangeObjectItems.map((option) => ({
      optionValue: option.value,
      value: option.id,
      title: `(${option.value}) ${option.description}`,
    }))
    return [...dataEbateValueFromApi]
  }
  const fetchCrossReferenceValues = async () => {
    const request: CrossReferencedValueRequest = {
      exchangeFormatId: data.exchangeFormatId,
      searchQuery: data.query,
    }
    const response = await getCrossReferencedValues(request)

    if (response?.status === 200) {
      return onCompletedEbateValues(response.data)
    }
    return []
  }
  const crossReferenceValues = useEbateQuery(
    [
      ...ebateQueryKeys.QUERY_KEY_CROSS_REFERENCE_VALUES,
      data.exchangeFormatId?.toString(),
      data.query,
    ],
    fetchCrossReferenceValues,
    {
      cacheTime: CacheTime.medium,
      staleTime: StaleTime.medium,
      enabled:
        data !== undefined &&
        data !== null &&
        data.exchangeFormatId !== null &&
        data.exchangeFormatId !== undefined,
    }
  )
  return crossReferenceValues
}
